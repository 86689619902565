import React,{Fragment,useState,useEffect} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../actions/actions';
import {pathSpliter} from '../lib/functions';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import cyan from '@material-ui/core/colors/cyan';
import StyleIcon from '@material-ui/icons/Style';
import NoteIcon from '@material-ui/icons/Note';
import {accessChecker} from '../lib/functions';
import reportsIcon from '../icons/reports.png'

const color = cyan[500];

const useStyles = makeStyles((theme) => ({
      box:{
        color: 'white',
        height: '100px',
        textAlign: 'center',
        borderRadius: '5px',
        textDecoration: 'none',
        background: color,
        margin: '10px',
        marginTop: '0px'
      },
      salesBox:{
        backgroundColor:'#00616d'
      },
      purchaseBox:{
        backgroundColor:'#00616d'
      },
      productionBox:{
        backgroundColor:'#00616d'
      },
      serviceBox:{
        backgroundColor:'#00616d'
      },
      stockBox:{
        backgroundColor:'#00616d'
      },
      accountsBox:{
        backgroundColor:'#00616d'
      },
      reportsBox:{
        backgroundColor:'#00616d'
      },
      hrPayrollBox:{
        backgroundColor:'#00616d'
      },
      administrationBox:{
        backgroundColor:'#00616d'
      },
      boxTitle:{
        color: '#484848',
        
      },
      '@global': {
        '.MuiBox-root':{
          paddingBottom:'0px !important',
          paddingTop: '20px'
        },
        '.MuiBox-root p':{
          color:'white',
          marginTop: '3px'
        },
        '.MuiBox-root svg':{
          color:'white',
          fontWidth:'bold'
        }
      }
}));

const ModuleDetail = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles()
    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1))
    },[])
    return(<Fragment>


<Grid container spacing={1}>
{
         currentRoute=='quotation'?(<>
         {
  accessChecker('quotation_entry') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.salesBox]}>
          <Link to="/quotation/quotation-entry" >
              <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Quotation Entry</p>

              </Box> 
            </Link>
          </Grid>
     </>
  ):''
}


{
  accessChecker('quotation_record') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.salesBox]}>
          <Link to="/quotation/quotation-record" >
              <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Quotation Record</p>

              </Box> 
            </Link>
          </Grid>
     </>
  ):''
}

         
         </>):""
      }

</Grid>


        {
            currentRoute=='sales'?(
    <Grid container spacing={1}>

{
  accessChecker('sales_entry') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.salesBox]}>
          <Link to="/sales/sales-entry" >
              <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Sales Entry</p>

              </Box> 
            </Link>
          </Grid>
     </>
  ):''
}

         
{
  accessChecker('sales_return') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.salesBox]}>
          <Link to="/sales/sales-return-entry" >
              <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Sales Return Entry</p>

              </Box> 
            </Link>
          </Grid>
     </>
  ):''
}
          
{
  accessChecker('sales_voucher') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.salesBox]}>
          <Link to="/sales/view-sales-voucher" >
              <Box   p={4} >
                  <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                  <p className={classes.boxTitle}>View Sales Vouchers</p>

              </Box> 
            </Link>
          </Grid>
     </>
  ):''
}


{
  accessChecker('sales_return_voucher') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.salesBox]}>
          <Link to="/sales/view-sales-return-voucher" >
              <Box   p={4} >
                  <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                  <p className={classes.boxTitle}>View Sales Return Vouchers</p>

              </Box> 
            </Link>
          </Grid>

     </>
  ):''
}


{
  accessChecker('sales_record') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.salesBox]}>
          <Link to="/sales/sales-record" >
              <Box   p={4} >
                  <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                  <p className={classes.boxTitle}>Sales Record</p>

              </Box> 
            </Link>
          </Grid>
     </>
  ):''
}
         
{
  accessChecker('sales_return_record') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.salesBox]}>
          <Link to="/sales/sales-return-record" >
              <Box   p={4} >
                  <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                  <p className={classes.boxTitle}>Sales Return Record</p>

              </Box> 
            </Link>
          </Grid>
     </>
  ):''
}

          


    
    </Grid>
          ):''
        }

        {
          currentRoute == 'service' ?(<>

          <Grid container spacing={1}> 

          {
  accessChecker('service_entry') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
          <Link to="/service/service-entry" >
              <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Service Entry</p>

              </Box> 
            </Link>
          </Grid>
     </>
  ):''
}

{
  accessChecker('service_expense_entry') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
          <Link to="/service/service-expense-entry" >
              <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Service Expense Entry</p>

              </Box> 
            </Link>
          </Grid>
     </>
  ):''
}

{
  accessChecker('service_voucher') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
          <Link to="/service/view-service-vouchers" >
              <Box   p={4} >
                  <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                  <p className={classes.boxTitle}>View Service  Vouchers</p>

              </Box> 
            </Link>
          </Grid>
     </>
  ):''
}

{
  accessChecker('service_expense_voucher') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
          <Link to="/service/view-service-expense-vouchers" >
              <Box   p={4} >
                  <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                  <p className={classes.boxTitle}>View Service Expense  Vouchers</p>

              </Box> 
            </Link>
          </Grid>
     </>
  ):''
}

{
  accessChecker('service_record') > -1?(
     <>
	  <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
          <Link to="/service/service-record" >
              <Box   p={4} >
                  <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                  <p className={classes.boxTitle}> Service Record</p>

              </Box> 
            </Link>
          </Grid>
     </>
  ):''
}

{
  accessChecker('service_expense_record') > -1?(
     <>
	  <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
          <Link to="/service/service-expense-record" >
              <Box   p={4} >
                  <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                  <p className={classes.boxTitle}> Service Expense Record</p>

              </Box> 
            </Link>
          </Grid>
     </>
  ):''
}

        




          </Grid>
            

          </>):''
        }







{
          currentRoute == 'service-report' ?(<>

          <Grid container spacing={1}> 

      
	 <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
          <Link to="/service-report/service-report-entry" >
              <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Service Report Entry</p>

              </Box> 
            </Link>
          </Grid>


          <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
          <Link to="/service-report/hand-over" >
              <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Handover Entry</p>

              </Box> 
            </Link>
          </Grid>


          <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
          <Link to="/service-report/visit-entry" >
              <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Visit Entry</p>

              </Box> 
            </Link>
          </Grid>


          <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
          <Link to="/service-report/report-record" >
              <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Service Report  Record</p>

              </Box> 
            </Link>
          </Grid>


          <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
          <Link to="/service-report/visit-record" >
              <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Visit  Record</p>

              </Box> 
            </Link>
          </Grid>


          <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
          <Link to="/service-report/handover-record" >
              <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Handover  Record</p>

              </Box> 
            </Link>
          </Grid>
   

          </Grid>
            

          </>):''
        }





        
{
            currentRoute =='order'?(<>
               <Grid container>


                     
              {
                accessChecker('purchase_order_entry') > -1?(
                  <>
                    <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
                <Link to="/order/purchase-order-entry" >
                <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Purchase Requisition Entry</p>
                    </Box> 
                  </Link>
                </Grid>
                  </>
                ):''
              }


{
                accessChecker('purchase_order_return_entry') > -1?(
                  <>
                    <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
                <Link to="/order/purchase-order-entry" >
                <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Purchase Return Requisition Entry</p>
                    </Box> 
                  </Link>
                </Grid>
                  </>
                ):''
              }


               {
                accessChecker('sales_order_entry') > -1?(
                  <>
                    <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
                <Link to="/order/sales-order-entry" >
                <Box   p={4} >
                  <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                  <p className={classes.boxTitle}>Sales Order Entry</p>
                    </Box> 
                  </Link>
                </Grid>
                  </>
                ):''
              }

          
{
  accessChecker('purchase_order_voucher') > -1?(
     <>
	
  <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
                <Link to="/order/view-purchase-order-voucher" >
                <Box   p={4} >
                <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                  <p className={classes.boxTitle}>Purchase requisition Vouchers</p>
                    </Box> 
                  </Link>
                </Grid>
     </>
  ):''
}


{
  accessChecker('purchase_order_record') > -1?(
     <>
	   <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
                <Link to="/order/purchase-order-record" >
                <Box   p={4} >
                <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                  <p className={classes.boxTitle}>Purchase requisition Record</p>
                    </Box> 
                  </Link>
                </Grid>
     </>
  ):''
}


{
  accessChecker('purchase_order_return_record') > -1?(
     <>
	   <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
                <Link to="/order/purchase-order-record-return" >
                <Box   p={4} >
                <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                  <p className={classes.boxTitle}>Purchase return requisition Record</p>
                    </Box> 
                  </Link>
                </Grid>
     </>
  ):''
}
              {
                accessChecker('sales_order_voucher') > -1?(
                  <>
                  <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
                <Link to="/order/view-sales-order-voucher" >
                <Box   p={4} >
                <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                  <p className={classes.boxTitle}>Sales Order Vouchers</p>
                    </Box> 
                  </Link>
                </Grid>
                  </>
                ):''
              }

                


{
  accessChecker('sales_order_record') > -1?(
     <>
	  <Grid item xs={12} sm={2} className={[classes.box,classes.serviceBox]}>
                <Link to="/order/sales-order-record" >
                <Box   p={4} >
                <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                  <p className={classes.boxTitle}>Sales Order Record</p>
                    </Box> 
                  </Link>
                </Grid>
     </>
  ):''
}


              

               </Grid>
            </>):''
          }

          {
            currentRoute == 'purchase'?(<>
              <Grid container> 
              {
  accessChecker('purchase_entry') > -1?(
     <>
	   <Grid item xs={12} sm={2} className={[classes.box,classes.purchaseBox]}>
                    <Link to="/purchase/purchase-entry" >
                    <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Purchase  Entry</p>
                        </Box> 
                      </Link>
                    </Grid>

     </>
  ):''
}
{
  accessChecker('purchase_return') > -1?(
     <>
	  <Grid item xs={12} sm={2} className={[classes.box,classes.purchaseBox]}>
                    <Link to="/purchase/purchase-return-entry" >
                    <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Purchase Return Entry</p>
                        </Box> 
                      </Link>
                    </Grid>

     </>
  ):''
}
                  
{
  accessChecker('purchase_voucher') > -1?(
     <>
	     <Grid item xs={12} sm={2} className={[classes.box,classes.purchaseBox]}>
                    <Link to="/purchase/view-purchase-voucher" >
                    <Box   p={4} >
                    <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                      <p className={classes.boxTitle}>View Purchase Vouchers</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}
{
  accessChecker('purchase_return_voucher') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.purchaseBox]}>
                    <Link to="/purchase/view-purchase-return-voucher" >
                    <Box   p={4} >
                    <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                      <p className={classes.boxTitle}>View Purchase Return Vouchers</p>
                        </Box> 
                      </Link>
                    </Grid>

     </>
  ):''
}

{
  accessChecker('purchase_record') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.purchaseBox]}>
                    <Link to="/purchase/purchase-record" >
                    <Box   p={4} >
                    <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                      <p className={classes.boxTitle}>Purchase Record</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}     
                    
{
  accessChecker('purchase_return_record') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.purchaseBox]}>
                    <Link to="/purchase/purchase-return-record" >
                    <Box   p={4} >
                    <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                      <p className={classes.boxTitle}>Purchase Return Record</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}
                    

              </Grid>
            </>):''
          }


          {
            currentRoute == 'manufacturing'?(<>
                 <Grid container>

                 {
                  accessChecker('production_entry') > -1?(
                    <>
                    <Grid item xs={12} sm={2} className={[classes.box,classes.productionBox]}>
                    <Link to="/manufacturing/manufacturing-journal-entry" >
                    <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Manufacturing Journal Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
                    </>
                  ):''
                }


              {
                accessChecker('production_voucher') > -1?(
                  <>
                 <Grid item xs={12} sm={2} className={[classes.box,classes.productionBox]}>
                    <Link to="/manufacturing/view-manufacturing-voucher" >
                    <Box   p={4} >
                    <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                      <p className={classes.boxTitle}>Manufacturing Journal Vouchers</p>
                        </Box> 
                      </Link>
                    </Grid>
                  </>
                ):''
              }


               {
                accessChecker('production_record') > -1?(
                  <>
                      <Grid item xs={12} sm={2} className={[classes.box,classes.productionBox]}>
                    <Link to="/manufacturing/manufacturing-record" >
                    <Box   p={4} >
                    <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                      <p className={classes.boxTitle}>Manufacturing Journal Record</p>
                        </Box> 
                      </Link>
                    </Grid>
                  </>
                ):''
              }


                 </Grid>
            </>):''
          }

          {
           currentRoute == 'inventory'?(<>
               <Grid container>
               {
  accessChecker('item_stock_report') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.stockBox]}>
                      <Link to="/inventory/item-stock-report" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Item Stock Report</p>
                          </Box> 
                        </Link>
                  </Grid>

     </>
  ):''
}

{
  accessChecker('item_ledger') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.stockBox]}>
                    <Link to="/inventory/item-ledger" >
                    <Box   p={4} >
                    <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                      <p className={classes.boxTitle}>Item Ledger</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}
     

{
  accessChecker('transfer_entry') > -1?(
     <>
	  <Grid item xs={12} sm={2} className={[classes.box,classes.stockBox]}>
                      <Link to="/inventory/transfer-entry" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                        <p className={classes.boxTitle}>Transfer Entry</p>
                          </Box> 
                        </Link>
                  </Grid>
     </>
  ):''
}
                

{
  accessChecker('item_adjustment_entry') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.stockBox]}>
                      <Link to="/inventory/item-adjustment" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                        <p className={classes.boxTitle}>Item Adjustment Entry</p>
                          </Box> 
                        </Link>
                  </Grid>
     </>
  ):''
}
       

{
  accessChecker('adjustment_record') > -1?(
     <>
	   <Grid item xs={12} sm={2} className={[classes.box,classes.stockBox]}>
                    <Link to="/inventory/adjustment-record" >
                    <Box   p={4} >
                    <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                      <p className={classes.boxTitle}>Adjustment Record</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}
 

{
  accessChecker('transfer_record') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.stockBox]}>
                    <Link to="/inventory/transfer-record" >
                    <Box   p={4} >
                    <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                      <p className={classes.boxTitle}>Transfer Record</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}


{
  accessChecker('transfer_pending_record') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.stockBox]}>
                    <Link to="/inventory/transfer-pending-record" >
                    <Box   p={4} >
                    <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                      <p className={classes.boxTitle}>Transfer Pending Record</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}

{
  accessChecker('transfer_receive_record') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.stockBox]}>
                    <Link to="/inventory/transfer-receive-record" >
                    <Box   p={4} >
                    <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                      <p className={classes.boxTitle}>Transfer Receive Record</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}


                    

                   


              </Grid>
           </>):''
          }

          {
            currentRoute == 'accounts'?(<>
              <Grid container>

              {
  accessChecker('creditor_payment') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.accountsBox]}>
                      <Link to="/accounts/creditor-payment-entry" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Creditor Payment Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}

                  
{
  accessChecker('debtor_receipt') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.accountsBox]}>
                      <Link to="/accounts/debitor-receipt-entry" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Debtor Receipt Entry</p>
                        </Box> 
                      </Link>
                    </Grid>

     </>
  ):''
}

{
  accessChecker('expense_entry') > -1?(
     <>
	  
    <Grid item xs={12} sm={2} className={[classes.box,classes.accountsBox]}>
                      <Link to="/accounts/expense-entry" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Expense Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}

                 
{
  accessChecker('income_entry') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.accountsBox]}>
                      <Link to="/accounts/income-entry" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Income Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}

{
  accessChecker('contra_entry') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.accountsBox]}>
                      <Link to="/accounts/contra-entry" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Contra/ Single Entry</p>
                        </Box> 
                      </Link>
                    </Grid>

     </>
  ):''
}



{
  accessChecker('advance_transaction_entry') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.accountsBox]}>
                      <Link to="/accounts/advance-tran-entry" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Advance Transaction Entry</p>
                        </Box> 
                      </Link>
                    </Grid>

     </>
  ):''
}


                    
{
  accessChecker('journal_entry') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.accountsBox]}>
                      <Link to="/accounts/journal-entry" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Journal/ Double Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}

{
  accessChecker('branch_tran') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.accountsBox]}>
                      <Link to="/accounts/branch-tran" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Branch Transaction Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}


{
  accessChecker('account_entry') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.accountsBox]}>
                      <Link to="/accounts/account-entry" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Account Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}

{
  accessChecker('location_entry') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.accountsBox]}>
                      <Link to="/accounts/location-manage" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Location Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}


                   

                   

                   

              </Grid>
            </>):''
          }

          {
            currentRoute == 'hrpayroll'?(<>
              <Grid container>
              {
                accessChecker('salary_payment') > -1?(
                  <>
                    <Grid item xs={12} sm={2} className={[classes.box,classes.hrPayrollBox]}>
                      <Link to="/hrpayroll/salary-payment-entry" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Salary Payment Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
                  </>
                ):''
              }


{
  accessChecker('attendance_entry') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.hrPayrollBox]}>
                      <Link to="/hrpayroll/attendance-entry" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 

                      <p className={classes.boxTitle}>Attendance Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}

{
  accessChecker('employee_entry') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.hrPayrollBox]}>
                      <Link to="/hrpayroll/employee-entry" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Employee Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}

{
  accessChecker('department_entry') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.hrPayrollBox]}>
                      <Link to="/hrpayroll/department-entry" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Department Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}
                    
{
  accessChecker('designation_entry') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.hrPayrollBox]}>
                      <Link to="/hrpayroll/designation-entry" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Designation Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}


{
  accessChecker('salary_report') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.hrPayrollBox]}>
                      <Link to="/hrpayroll/salary-report" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 

                      <p className={classes.boxTitle}>Salary Payment Report</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}
                    

                   
{
  accessChecker('monthly_salary_report') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.hrPayrollBox]}>
                      <Link to="/hrpayroll/monthly-salary-report" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 

                      <p className={classes.boxTitle}>Monthly salary Report</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}



        

              </Grid>
            </>):''
          }

          {
            currentRoute == 'settings'?(<>
                 <Grid container>
                 {
  accessChecker('item_entry') > -1?(
     <>
	  <Grid item xs={12} sm={2} className={[classes.box,classes.administrationBox]}>
                      <Link to="/settings/item-manage" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Item Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}

{
  accessChecker('item_unit') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.administrationBox]}>
                      <Link to="/settings/unit-manage" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Unit Entry & measurement</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}


{
  accessChecker('group_entry') > -1?(
     <>
	
  <Grid item xs={12} sm={2} className={[classes.box,classes.administrationBox]}>
                      <Link to="/settings/group-manage" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Group Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}
     
{
  accessChecker('item_category') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.administrationBox]}>
                      <Link to="/settings/category-manage" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Category Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}


{
  accessChecker('warehouse_entry') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.administrationBox]}>
                      <Link to="/settings/warehouse-manage" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Warehouse Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}


{
  accessChecker('branch_entry') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.administrationBox]}>
                      <Link to="/settings/branch-manage" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Branch Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}


{
  accessChecker('sms_sender') > -1?(
     <>
	 <Grid item xs={12} sm={2} className={[classes.box,classes.administrationBox]}>
                      <Link to="/settings/sms-sender" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Sms Sender</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}


{
  accessChecker('user_entry') || authInfo.role == 'super_admin' > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.administrationBox]}>
                      <Link to="/settings/user-manage" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>User Entry</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}

                   

{
  accessChecker('company_profile') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.administrationBox]}>
                      <Link to="/settings/institution-profile" >
                      <Box   p={4} >
                      <PlaylistAddIcon style={{textAlign:'center'}} /><br/> 
                      <p className={classes.boxTitle}>Company Profile</p>
                        </Box> 
                      </Link>
                    </Grid>
     </>
  ):''
}


                    
                 </Grid>
            </>):''
          }

          {
            currentRoute == 'reports'?(<>
              <Grid container>

              {
  accessChecker('balance_sheet') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/balance-sheet" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Balance Sheet</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}


{
  accessChecker('cash_bank_balance') > -1?(
     <>
 <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/account-balance" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Cash & Bank Accounts Balance</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}    

{
  accessChecker('trial_balance') > -1?(
     <>
	<Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/trial-balance" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Trial Balance</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}    

{
  accessChecker('profit_loss') > -1?(
     <>
<Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/profit-loss" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Profit Loss</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}    


{
  accessChecker('item_wise_profit_loss') > -1?(
     <>
<Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/item-wise-profit" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Item Wise Profit Loss</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}    



{
  accessChecker('loan_balance') > -1?(
     <>
 
 <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/loan-balance" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Loan Accounts Balance</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}    

{
  accessChecker('advance_debtor_balance') > -1?(
     <>
 
 <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/advance-debtor-balance" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Advance Debtor Balance</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}    

           {
  accessChecker('advance_creditor_balance') > -1?(
     <>
 
 <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/advance-creditor-balance" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Advance Creditor Balance</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}        
{
  accessChecker('item_stock_report') > -1?(
     <>
 
 <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/item-stock-report" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Item Stock Report</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}    

{
  accessChecker('indirect_expense_balance') > -1?(
     <>
   <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/indirect-expenses-balance" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Indirect Expense Accounts  Balance</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}        

{
  accessChecker('indirect_income_balance') > -1?(
     <>
    <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/indirect-income-balance" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Indirect Income Accounts Balance</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}        

{
  accessChecker('fixed_asset_balance') > -1?(
     <>
      <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/fixed-asset-balance" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Fixed Asset Accounts Balance</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}        
               
{
  accessChecker('capital_balance') > -1?(
     <>
      <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/capitals-balance" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Capital Accounts Balance</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}       
              
{
  accessChecker('debtor_balance') > -1?(
     <>
      <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/debitors-balance" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Debtor Accounts Balance</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}     


{
  accessChecker('branch_balance') > -1?(
     <>
      <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/branch-balance-report" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Branch Balance Report</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}     
      
               

{
  accessChecker('creditor_balance') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/creditors-balance" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Creditor Accounts Balance</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}     


               
{
  accessChecker('daily_ledger') > -1?(
     <>
         <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/daily-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Daily Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}     

{
  accessChecker('fixed_asset_ledger') > -1?(
     <>
        <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/fixed-asset-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Fixed Asset Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}     


{
  accessChecker('loan_ledger') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/loan-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Loan Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}     
               


{
  accessChecker('indirect_expense_ledger') > -1?(
     <>
        <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/indirect-expense-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Indirect Expense Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}     
               

{
  accessChecker('indirect_income_ledger') > -1?(
     <>
         <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/indirect-income-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Indirect Income Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}         

               
{
  accessChecker('cash_bank_ledger') > -1?(
     <>
         <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/account-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Cash & Bank Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}        

{
  accessChecker('capital_ledger') > -1?(
     <>
          <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/capital-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Capital Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}        

{
  accessChecker('debtor_ledger') > -1?(
     <>
          <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/debitor-balance-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Debtor Balance Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}        


{
  accessChecker('branch_ledger') > -1?(
     <>
          <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/branch-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Branch Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}        

{
  accessChecker('creditor_ledger') > -1?(
     <>
          <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/creditor-balance-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Creditor Balance Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}        

{
  accessChecker('sales_ledger') > -1?(
     <>
          <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/sales-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Sales Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}        
               


{
  accessChecker('purchase_ledger') > -1?(
     <>
           <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/purchase-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Purchase Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  

             
{
  accessChecker('service_expense_ledger') > -1?(
     <>
         <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/service-expense-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Service Expense Account Ledger</p>
                          </Box> 

                        </Link>
                </Grid>
     </>
  ):''
}  
              

{
  accessChecker('service_ledger') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/service-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Service Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  

               
{
  accessChecker('sales_return_ledger') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/sales-return-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Sales Return Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>

     </>
  ):''
}  


        {
  accessChecker('purchase_return_ledger') > -1?(
     <>
        <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/purchase-return-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>purchase Return Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>

     </>
  ):''
}  

{
  accessChecker('tax_ledger') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/tax-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Tax Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}     


{
  accessChecker('debtor_receipt_record') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/debtor-receipt-record" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Debtor Receipt Record</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  


{
  accessChecker('creditor_payment_record') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/creditor-payment-record" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Creditor Payment Record</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  

{
  accessChecker('expense_record') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/expense-record" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Expense Record</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  


{
  accessChecker('income_record') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/income-record" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Income Record</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  

{
  accessChecker('income_record') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/income-record" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Income Record</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  

{
  accessChecker('journal_record') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/journal-record" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Journal Record</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  


{
  accessChecker('contra_record') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/contra-record" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Contra Record</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  
{
  accessChecker('branch_tran_pen_list') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/branch-tran-pending-list" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Branch tarnsaction pending list</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  



{
  accessChecker('branch_tran_rcv_list') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/branch-tran-receive-list" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Branch tarnsaction received list</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  

{
  accessChecker('branch_tran_transfer_list') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/branch-tran-transfer-list" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Branch tarnsaction transfer list</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  


{
  accessChecker('sales_record') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/sales-record" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Sales Record</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  

{
  accessChecker('sales_return_record') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/sales-return-record" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Sales Return Record</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  

{
  accessChecker('purchase_record') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/purchase-record" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Purchase Record</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  

{
  accessChecker('purchase_return_record') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/purchase-return-record" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Purchase Return Record</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  

{
  accessChecker('production_record') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/manufacturing-record" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Manufacturing Record</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  

{
  accessChecker('salary_report') > -1?(
     <>
       <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/salary-report" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Salary Payment Report</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}  

{
  accessChecker('direct_expense_balance') > -1?(
     <>
         <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/direct-expense-balance" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Direct Expense  Balance</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}       

{
  accessChecker('direct_expense_ledger') > -1?(
     <>
         <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/direct-expense-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Direct Expense  Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}       

               
{
  accessChecker('direct_income_balance') > -1?(
     <>
        <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/direct-income-balance" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Direct Income  Account Balance</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}       


            {
  accessChecker('direct_income_ledger') > -1?(
     <>
      <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/direct-income-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Direct Income  Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}           



{
  accessChecker('direct_income_ledger') > -1?(
     <>
      <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/direct-income-ledger" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Direct Income  Account Ledger</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}     


{
  accessChecker('salary_report') > -1?(
     <>
      <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/salary-report" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Salary Payment Report</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}     

{
  accessChecker('monthly_salary_report') > -1?(
     <>
      <Grid item xs={12} sm={2} className={[classes.box,classes.reportsBox]}>
                      <Link to="/reports/monthly-salary-report" >
                      <Box   p={4} >
                      <img src={reportsIcon} style={{width:'20px',height:'20px'}} /><br/> 
                        <p className={classes.boxTitle}>Monthly Salary Report</p>
                          </Box> 
                        </Link>
                </Grid>
     </>
  ):''
}     
               

              </Grid>
            </>):''
          }
  </Fragment>
    )
}
const mapStateToProps = (state)=>{
      return{
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToProps,{currentRouteSet})(ModuleDetail)