import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {acc_types} from '../../account_types.json';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import DateFnsUtils from '@date-io/date-fns';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import moment from 'moment';



import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {pathSpliter,dateTimeFormat,checkIntNum,currentDateTime,getDateTimeFromISODT,dateFormat} from '../../lib/functions'


import {currentRouteSet} from '../../actions/actions';


const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getAccounts();
    getLocations()
    getEmployees()
    getAccCode()
    currentRouteSet(pathSpliter(location.pathname,1));


    if(pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0 != 0){
      getVisitRecord()

    }
  },[])

    // Init states start
 
    
    let [accounts,accountsSet] = useState([])
    let [customers,customersSet] = useState([])
    let [locations,locationsSet] = useState([])
    let [employees,employeesSet] = useState([])
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)
    let [selectedAccountType,selectedAccountTypeSet] = useState(null)
    let [report_no,report_no_set] = useState('')

    let [amc,amc_set] = useState(false)
    let [po_wo,po_wo_set] = useState(false)
    let [under_warranty,under_warranty_set] = useState(false)
    let [on_call_service,on_call_service_set] = useState(false)

    
    

    let [action,action_set] = useState('create');
    let [id,id_set] = useState(0);
    let [company_name,company_name_set] = useState('');
    let [address,address_set] = useState('');
    let [contact_person,contact_person_set] = useState('');
    let [designation,designation_set] = useState('');
    let [cell_number,cell_number_set] = useState('');
    let [email_address,email_address_set] = useState('');
    let [name_of_product,name_of_product_set] = useState('');
    let [application,application_set] = useState('');
    let [_1st_met_remark,_1st_met_remark_set] = useState('');
    let [_2nd_met_remark,_2nd_met_remark_set] = useState('');
    let [cus_repre_name,cus_repre_name_set] = useState('');
    let [_3rd_met_remark,_3rd_met_remark_set] = useState('');
    let [ser_for,ser_for_set] = useState('');

    let [service_by_name,service_by_name_set] = useState('');
    let [service_by_designation,service_by_designation_set] = useState('');
    let [service_by_id,service_by_id_set] = useState('');
 
    let [selectedEmployee,selectedEmployeeSet] = useState(null);
    
    
    const [visit_date, visit_date_set] = useState(currentDateTime);
    const [_1st_met_date, _1st_met_date_set] = useState(currentDateTime);
    const [_2nd_met_date, _2nd_met_date_set] = useState(currentDateTime);
    const [_3rd_met_date, _3rd_met_date_set] = useState(currentDateTime);
    const [brand, brand_set] = useState('');
    const [final_status, final_status_set] = useState('');

    

    
    const getVisitRecord = async ()=>{
      await axios.post(`${API_URL}/api/get-visit-record`,{id : pathSpliter(location.pathname,3)},{headers:{'auth-token':authInfo.token}}).then(res=>{
        let data = res.data[0];
        id_set(data.id)
        action_set('update')
        company_name_set(data.company_name)
        address_set(data.address)
        contact_person_set(data.contact_person)
        designation_set(data.designation)
        cell_number_set(data.cell_number)
        email_address_set(data.email_address)
        name_of_product_set(data.name_of_product)
        application_set(data.application)
        visit_date_set(data.visit_date)
        _1st_met_date_set(data._1st_met_date)
        _1st_met_remark_set(data._1st_met_remark)
        _2nd_met_date_set(data._2nd_met_date)
        _2nd_met_remark_set(data._2nd_met_remark)
        _3rd_met_date_set(data._3rd_met_date)
        _3rd_met_remark_set(data._3rd_met_remark)
        brand_set(data.brand)
        final_status_set(data.final_status)
        selectedEmployeeSet({employee_id: data.employee_id,employee_name : data.employee_name,display_text : data.employee_name})
      })
    }



    const handleDateChange = (date) => {
        visit_date_set(date);
      };


      

      const handleDateChange1st = (date) => {
        _1st_met_date_set(date);
      };


      const handleDateChange2nd = (date) => {
        _2nd_met_date_set(date);
      };

      const handleDateChange3rd = (date) => {
        _3rd_met_date_set(date);
      };
    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{
         
            loadingSaveSet(true);

            await axios.post(`${API_URL}/api/save-visit-entry`,{
              employee_id : selectedEmployee != null ? selectedEmployee.employee_id : 0,
              company_name:company_name.trim(),
              address,
              contact_person,
              designation,
              cell_number,
              email_address,
              name_of_product,
              application,
              visit_date,
              _1st_met_date,
              _1st_met_remark,
              _2nd_met_date,
              _2nd_met_remark,
              _3rd_met_date,
              _3rd_met_remark,
              brand,
              final_status,
              action,
              id
            },{headers:{'auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
              if(res.data.error){
                swal({
                  title: res.data.msg,
                  icon:'warning'
                })

              }else{
                swal({
                  title: res.data.msg,
                  icon:'success'
                })
                window.location.reload()
              }
              
            });
          
          
    }

    let [defaults,defaultsSet] = useState(['Discount on purchase','discount on service','Vat & Tax Account','Discount on sale',
                                'Sales','Sales Return','Discount on service','Purchase','Purchase Return','Salary','Services','Service Expense'])


    const getAccCode = async ()=>{
      await axios.post(`${API_URL}/api/get-acc-code`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        report_no_set(res.data)
      })
    }

  
    const getAccounts = async ()=>{
      await axios.post(`${API_URL}/api/get-accounts`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        accountsSet(res.data)
      })
    }

    const getLocations = async ()=>{
      await axios.post(`${API_URL}/api/get-locations`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        locationsSet(res.data)
      })
    }


    const getEmployees = async ()=>{
      await axios.post(`${API_URL}/api/get-employees`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        employeesSet(res.data)
      })
    }



    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>accountEdit(props.rowData)}/>
      
        {/* <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>accountDelete(props.rowData)}/> */}
            
   </>):''
 }
      
      </div>)
    
    }


    const accountEdit = (row,index)=>{
      let account =  accounts.filter((account)=>account.id == row[0])

      // if(account[0].company_name.trim().toLowerCase() == 'cash'){
      //   swal({
      //     title: `This Account  Can't be Edit.`,
      //     icon:'warning'
      //   });
      //   return false
      // }

      
      let result =   defaults.findIndex((acc)=>{
        let real = acc.trim().toLowerCase()
        let check = account[0].company_name.trim().toLowerCase()
          return real == check
        })

     if(result > -1){
      swal({
        title: `This Account  Can't be Edit.`,
        icon:'warning'
      });
      return false
     }


      id_set(account[0].id)
      company_name_set(account[0].company_name)
      action_set('update')


      selectedEmployeeSet({employee_name:account[0].employee_name,employee_id:account[0].employee_id})
   
      report_no_set(account[0].report_no)

      



    }
    const [customer_name, customer_name_set] = useState("");



    useEffect(()=>{
        getCustomers()
        },[customer_name])


    const getCustomers = async() => {
        await axios.post(`${API_URL}/api/get-accounts-by-search`,{query:customer_name.trim(),type:'debitor'},{headers:{'auth-token':authInfo.token}}).then(res=>{
       
         let newArray = res.data;
          customersSet(newArray)
   
         
   
   
       })
   };
   


    const accountDelete = async (row)=>{

      let account =  accounts.filter((account)=>account.id == row[0])


      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{

        

      let result =   defaults.findIndex((acc)=>{
        let real = acc.trim().toLowerCase()
        let check = account[0].company_name.trim().toLowerCase()
          return real == check
        })

     if(result > -1){
      swal({
        title: `This Account  Can't be Deleted.`,
        icon:'warning'
      });
      return false
     }
    
        if(yes){
          await axios.post(`${API_URL}/api/delete-account`,{id:account[0].id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getAccounts();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "id",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "company_name",label: "Account Name",options: {filter: true,sort: true}},
      {name: "acc_type_name",label: "Account Type",options: {filter: true,sort: true}},
      {name: "report_no",label: "Account Code",options: {filter: true,sort: true}},
      {name: "opening_balance",label: "Opening Balance",options: {filter: true,sort: true}},

      {name: "creation_date",label: "Creation Date",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ moment(tableMeta.rowData[5]).format(dateFormat) }</p>) 
        }
      }
      
    },

      
      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }




    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Visit Entry </h2>


      <Grid item xs={12} sm={3}> 
        <Link to="/hrpayroll/employee-entry" className={classes.plusLink} style={{cursor:'pointer'}}  >+</Link>  

              <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={employees} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.display_text}
              value={selectedEmployee}
              onChange={(event,selectedObj)=>{
                selectedEmployeeSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                // inputRef={location_ref}
                // onKeyDown={event => {
                //   if (event.key === "Enter") {
                //     email_ref.current.focus()
                //   }
                // }}
                  {...params}
                  label="Select Visitor Employee"
                  variant="outlined"
                
                />
              )}
          />
        </Grid> 



          <h3 style={{textAlign:'left'}}>FIRST VISIT STATUS: </h3>
        <Grid container spacing={3} style={{border:'2px solid #222'}}>
            <Grid item xs={12} sm={3}> 
            <TextField  autoComplete='off' style={{marginBottom:'8px'}} className={classes.fullWidth} 
        
            label="Company Name" name="company_name" value={company_name} variant="outlined" size="small" onChange={(e)=>company_name_set(e.target.value)} />
            </Grid>

            <Grid item xs={12} sm={3}> 
        <TextareaAutosize  value={address}  name="address" onChange={(e)=>address_set(e.target.value)} style={{float:'left',marginTop:'',width: '100%'}} aria-label="Address" 
         rowsMin={3} placeholder="Address" />
        </Grid>


        <Grid item xs={12} sm={3} style={{marginBottom:'20px'}}> 
        <TextField  autoComplete='off'  className={classes.fullWidth} 
       
        label="Contact Person" name="contact_person" value={contact_person} variant="outlined" 
        size="small" onChange={(e)=>contact_person_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3} style={{marginBottom:'20px'}}> 
        <TextField  autoComplete='off'  className={classes.fullWidth} 
       
        label="Designation" name="designation" value={designation} variant="outlined" 
        size="small" onChange={(e)=>designation_set(e.target.value)} />
        </Grid>


        <Grid item xs={12} sm={3} style={{marginBottom:'20px'}}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
       
        label="Cell Number" name="cell_number" value={cell_number} variant="outlined" 
        size="small" onChange={(e)=>cell_number_set(e.target.value)} />
        </Grid>

       
    <Grid item xs={12} sm={3} style={{marginBottom:'20px'}}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
       
        label="Email Address" name="email_address" value={email_address} variant="outlined" 
        size="small" onChange={(e)=>email_address_set(e.target.value)} />
        </Grid>



        <Grid item xs={12} sm={3} style={{marginBottom:'20px'}}> 
            
            <TextareaAutosize  value={name_of_product}  name="name_of_product" onChange={(e)=>name_of_product_set(e.target.value)} style={{float:'left',marginTop:'',width: '100%'}} aria-label="Sevice Requirements" 
                rowsMin={3} placeholder="Name Of Product" />
            </Grid>

            <Grid item xs={12} sm={3} style={{marginBottom:'20px'}}> 
            
            <TextareaAutosize  value={application}  name="application" onChange={(e)=>application_set(e.target.value)} style={{float:'left',marginTop:'',width: '100%'}} aria-label="Service Detail" 
         rowsMin={3} placeholder="Application" />
            </Grid>


            <Grid item xs={12} sm={2} style={{marginTop:'-40px'}}> 
       


       
    <LocalizationProvider dateAdapter={AdapterDateFns}>
         <Stack spacing={3}>
           <DesktopDatePicker
             label="Visit Date" 
             inputFormat={dateTimeFormat}
             value={visit_date}
             onChange={(e)=>handleDateChange(e)}
             renderInput={(params) => <TextField {...params} />}
           />
           
         </Stack>
       </LocalizationProvider>


        </Grid>

        </Grid>

      
        

     



      

<Grid container spacing={3} style={{border:'2px solid #222',marginTop:'30px'}}>
       
<Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
            
            <TextareaAutosize  value={_1st_met_remark}  name="_1st_met_remark" onChange={(e)=>_1st_met_remark_set(e.target.value)} style={{float:'left',marginTop:'',width: '100%'}} aria-label="_1st_met_remark" 
         rowsMin={6} placeholder="1st Metting Remark" />
            </Grid>


                <Grid item xs={12} sm={6} style={{marginTop:''}}> 
       
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="1st Meeting Date" 
          inputFormat={dateTimeFormat}
          value={_1st_met_date}
          onChange={(e)=>_1st_met_date_set(e)}
          renderInput={(params) => <TextField {...params} />}
        />
        
      </Stack>
    </LocalizationProvider>
        </Grid>

        </Grid>


        <Grid container spacing={3} style={{border:'2px solid #222',marginTop:'30px'}}>
        <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
            
           
            <TextareaAutosize  value={_2nd_met_remark}  name="_2nd_met_remark" onChange={(e)=>_2nd_met_remark_set(e.target.value)} style={{float:'left',marginTop:'',width: '100%'}} aria-label="customer comment" 
             rowsMin={6} placeholder="2nd Metting Remark" /> 
    
        </Grid>

        <Grid item xs={12} sm={6} style={{marginTop:''}}> 
       
       <LocalizationProvider dateAdapter={AdapterDateFns}>
         <Stack spacing={3}>
           <DesktopDatePicker
             label="2nd Meeting Date" 
             inputFormat={dateTimeFormat}
             value={_2nd_met_date}
             onChange={(e)=>_2nd_met_date_set(e)}
             renderInput={(params) => <TextField {...params} />}
           />
           
         </Stack>
       </LocalizationProvider>
           </Grid>
        </Grid>

        
          <Grid container spacing={3} style={{border:'2px solid #222',marginTop:'30px'}}>
          <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
             
              <TextareaAutosize  value={_3rd_met_remark}  name="_3rd_met_remark" onChange={(e)=>_3rd_met_remark_set(e.target.value)} style={{float:'left',marginTop:'',width: '100%'}} aria-label="3rd Meeting Remark" 
                rowsMin={6} placeholder="3rd Metting Remark" />
              
                </Grid>



           <Grid item xs={12} sm={6} style={{marginTop:''}}> 
       
       <LocalizationProvider dateAdapter={AdapterDateFns}>
         <Stack spacing={3}>
           <DesktopDatePicker
             label="3rd Meeting Date" 
             inputFormat={dateTimeFormat} 
             value={_3rd_met_date}
             onChange={(e)=>_3rd_met_date_set(e)}
             renderInput={(params) => <TextField {...params} />}
           />
           
         </Stack>
       </LocalizationProvider>
           </Grid>
          </Grid>



          
          <Grid container spacing={3} style={{border:'2px solid #222',marginTop:'30px'}}>
          <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Brand" name="COPETITOR Brand" value={brand} variant="outlined" 
                size="small" onChange={(e)=>brand_set(e.target.value)} />
                </Grid>


                <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Final Status" name="final_status" value={final_status} variant="outlined" 
                size="small" onChange={(e)=>final_status_set(e.target.value)} />
                </Grid>

          </Grid>




          






  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

    

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
      position:'relative'  
},
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);