import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {currentDateTime,convertNumberToWords, dateTimeFormat,dateFormat} from '../../../lib/functions'

import '../../commons/voucher.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const VoucherBody = ({salesOrderData,authInfo})=>{
    const classes = useStyles();
  

         let [salesOrder,salesOrderSet] = useState([])
          let [salesOrderDetails,salesOrderDetailsSet] = useState([])

          let [is_serial,is_serial_set] = useState('')
          let [is_cal_type,is_cal_type_set] = useState('')

        useEffect(()=>{
            salesOrderSet(salesOrderData)
            
            is_serial_set(salesOrderData.is_serial)
            is_cal_type_set(salesOrderData.is_cal_type)

            if(salesOrderData.details!=undefined){
                salesOrderDetailsSet(salesOrderData.details)
            }
            },[salesOrderData])

         


      return(
          <>

          {
            salesOrderDetails.findIndex((name => name.curr_type == 'USD')) > -1  ? (<>
           <p style={{fontWeight:'bold',margin:0}}>Foreign Part : {salesOrderData.foreign_part} </p> 
            <table className={'invoice-table'}> 
          <thead>
              <tr>
                <td  align="left"  width="1%">SL</td>
                <td align="left" width="5%"> Item Code</td>
                <td align="left" width="30%"> Item Name</td>
                {
                is_serial=='yes'?(<>
              <td align="left"   width="15%">Serials</td>

                </>):''
              }
              
              {
                authInfo.is_warehouse == 'yes-'?(<>
                  <td align="left"   width="15%">Warehouse</td>
                </>):''
              }
              <td align="right"  width="7%">Rate In USD</td>
              <td align="right"  width="5%">UNIT</td>
              <td align="right"  width="5%"> QTY</td>
             
              

              {
                is_cal_type == 'individual'?(<>
                     <td align="right"  width="5%">Discount%</td>
                     <td align="right"  width="5%">Vat%</td>
                </>):''
              }

              <td align="right" width="10%">Total Amount In USD</td>
              </tr>
                
             
          </thead>
          <tbody>
        
                    {
                        salesOrderDetails.filter(name => name.curr_type == 'USD').map((item,ind)=>(<>
                               <tr>
                                 <td  style={{textAlign:'left'}}>{parseFloat(1)+ind}</td>
                                 <td  style={{textAlign:'left'}}>{item.item_code}</td>
                                 <td  style={{textAlign:'left'}}>{item.item_name}</td>
                                 {
                                    is_serial == 'yes'?(<>
                                    <td align="left"  width="15%">
                                    {
                                        item.is_serial == 'yes'?(<>
                                            {
                                            <>
                                            ({item.serials.map((serial)=>(
                                        <> <span>{serial.serial_number}</span>, </> 
                                        ))})
                                        </>
                                         }
                                        </>):''
                                    }
                                  </td>
                    
                                   </>):''
                                  }

                  {
                   authInfo.is_warehouse =='yes-'?(<>
                          <td align="left">{item.warehouse_name}</td>
                   </>):''
                 }
                  <td align="right">{format(parseFloat(item.item_rate).toFixed(2))} <span style={{fontWeight:'bold'}}>$</span></td>
                  <td align="right">{item.per_unit_symbol}</td>
                  <td align="right">{item.item_qty}</td>
                  
                
                  {
                    is_cal_type == 'individual' ? (<>
                         <td align="right">{item.item_discount_per}%</td>
                          <td align="right">{item.item_tax_per}%</td>
                    </>):''
                  }
                
                  <td align="right">{format(parseFloat(item.item_total).toFixed(2))} <span style={{fontWeight:'bold'}}>$</span></td>

                                </tr>
                        </>))
                    }

                    {

                      <tr>
                        <td colSpan={6} style={{fontWeight:'bold',textAlign:'right'}}>Total In USD</td>
                                          <td align="right" style={{fontWeight:'bold'}}>{ format( salesOrderDetails.filter(name => name.curr_type == 'USD').reduce((prev,curr)=> prev + parseFloat(curr.item_total),0).toFixed(2))} <span style={{fontWeight:'bold'}}>$</span></td>

                      </tr>

                    }
          
                
           

               
              

              
          </tbody>
        </table>


        
        <Grid xs={12} sm={12}>
                                  <p style={{color: '#222'}}>In Word of USD  Total : {convertNumberToWords(salesOrderData.details != undefined && salesOrderData.details.length != 0 ?  format(parseFloat(salesOrderData.details.filter(name => name.curr_type == 'USD').reduce((prev,curr)=> prev + parseFloat(curr.item_total),0))) : 0)} ($) </p>

                                  <p style={{color: '#222'}}>Terms & Conditions : <p style={{whiteSpace: 'pre-line',margin: '0',marginLeft: '10px',padding: '0'}}>{salesOrderData.narration_f}</p> </p>
                            </Grid>
            
            </>):''
          }
       





<div style={{height:'20px'}}></div>


{
   salesOrderDetails.findIndex((name => name.curr_type == 'BDT')) > -1 ? (<>
     
     <p style={{fontWeight:'bold',margin:0}}>Local Part :  {salesOrderData.local_part}</p> 

<table className={'invoice-table'}> 
  <thead>
      <tr>
        <td  align="left"  width="1%">SL</td>
        <td align="left" width="5%"> Item Code</td>
        <td align="left" width="30%"> Item Name</td>
        {
        is_serial=='yes'?(<>
      <td align="left"   width="15%">Serials</td>

        </>):''
      }
      
      {
        authInfo.is_warehouse == 'yes-'?(<>
          <td align="left"   width="15%">Warehouse</td>
        </>):''
      }
      <td align="right"  width="7%">Rate In BDT</td>
      <td align="right"  width="5%">UNIT </td>
      <td align="right"  width="5%"> QTY</td>
     
      

      {
        is_cal_type == 'individual'?(<>
             <td align="right"  width="5%">Discount%</td>
             <td align="right"  width="5%">Vat%</td>
        </>):''
      }

      <td align="right" width="10%">Total Amount In BDT</td>
      </tr>
        
     
  </thead>
  <tbody>

            {
                salesOrderDetails.filter(name => name.curr_type == 'BDT').map((item,ind)=>(<>
                       <tr>
                         <td  style={{textAlign:'left'}}>{parseFloat(1)+ind}</td>
                         <td  style={{textAlign:'left'}}>{item.item_code}</td>
                         <td  style={{textAlign:'left'}}>{item.item_name}</td>
                         {
                            is_serial == 'yes'?(<>
                            <td align="left"  width="15%">
                            {
                                item.is_serial == 'yes'?(<>
                                    {
                                    <>
                                    ({item.serials.map((serial)=>(
                                <> <span>{serial.serial_number}</span>, </> 
                                ))})
                                </>
                                 }
                                </>):''
                            }
                          </td>
            
                           </>):''
                          }

          {
           authInfo.is_warehouse =='yes-'?(<>
                  <td align="left">{item.warehouse_name}</td>
           </>):''
         }
          <td align="right">{format(parseFloat(item.item_rate).toFixed(2))} <span style={{fontWeight:'bold'}}>৳</span></td>
          <td align="right">{item.per_unit_symbol}</td>
          <td align="right">{item.item_qty}</td>
          
        
          {
            is_cal_type == 'individual' ? (<>
                 <td align="right">{item.item_discount_per}%</td>
                  <td align="right">{item.item_tax_per}%</td>
            </>):''
          }
        
          <td align="right">{format(parseFloat(item.item_total).toFixed(2))} <span style={{fontWeight:'bold'}}>৳</span></td>

                        </tr>
                </>))
            }
  
        
  {

<tr>
<td colSpan={6} style={{fontWeight:'bold',textAlign:'right'}}>Sub Total In BDT</td>
            <td align="right" style={{fontWeight:'bold'}}>{ format( salesOrderDetails.filter(name => name.curr_type == 'BDT').reduce((prev,curr)=> prev + parseFloat(curr.item_total),0).toFixed(2))} <span style={{fontWeight:'bold'}}>৳</span></td>

</tr>

}

       
      

      
  </tbody>
</table>

   
   
   </>):''
}







          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
    
  }));

export default VoucherBody;
