import React,{useEffect,useState} from 'react'; 
import Axios from 'axios';
import {connect} from 'react-redux';
import {API_URL} from '../../config.json';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import './commons.css'
import Font, {Text} from 'react-font'

let InstitutionProfile = ({authInfo}) =>{
    let [institution,institutionSet] = useState(null);
    useEffect(()=>{
        getProfile()
    },[])

    let getProfile = async ()=>{
        await axios.get(`${API_URL}/api/get-institution`,{headers:{'auth-token':authInfo.token}}).then(res=>{
            institutionSet(res.data)
        });
    }

    return(
        <div className="print-source" >
            {
                 institution != null ? (<>
                 
                 <html lang="en">
                    <head>
                    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                    <meta charset="utf-8" />
                    <meta name="description" content="Static &amp; Dynamic Tables" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
                    <title>Profile</title>
                    </head>
                    <body>
                       
                          <div style={{width:'100%'}}>
                          <div className="invoice-logo" style={{width:'15%',float:'left',marginLeft:'0%',marginTop:'20px',overflow:'hidden'}}>
                                  <image alt="logo" src={`${API_URL}/${institution.pro_logo}`} style={{width:'80px',height:'80px'}} />
                            </div>
                            <div style={{width:'2%'}}></div>
                            <div className="invoice-desc" style={{width:'50%',float:'left',marginLeft:'5px'}}>
                                  <h2 style={{textAlign:'center'}}>{institution.pro_name}</h2>
                                  <Font family='Roboto'  sans-serif>
                                  <p style={{textAlign:'center',color:'#222',fontSize:'16px',margin:'0px',padding:'0px',whiteSpace:'pre-line'}}>{institution.pro_desc}</p>

                                  </Font>
                            </div>
                            <div style={{width:'2%'}}></div>

                            <div className="invoice-desc" style={{width:'30%',float:'right',marginRight:'5px',marginTop:'55px',border:'1px solid #222',padding:'3px',display:'inherit'}}>
                                  <p style={{textAlign:'left',color:'#222',fontSize:'16px',margin:'0px',padding:'0px',whiteSpace:'pre-line'}}>{institution.company_info}</p>

                            </div>
                          </div>
                               
                           
                            
                                    
                          
                    </body>
                    </html>
                 
                 </>):''
            }
                    
        </div>
    )
}

const mapStateToPops = (state)=>{
    return {
      authInfo:state.authInfoReducer
    }
}

export default connect(mapStateToPops,{})(InstitutionProfile);