import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';

import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,_p,sleep,dateTimeFormat,dateFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DdatateIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import {API_URL} from '../../config.json';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import _ from 'lodash';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import InstitutionProfile from '../commons/institution_profile'
import '../commons/commons.css'

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import swal from 'sweetalert';
import commaNumber from 'comma-number';
import { TableFooter } from 'semantic-ui-react';
let format = commaNumber.bindWith(',', '.')
const SalesRecord = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
        getLocations()
    },[]);

    let [types,typesSet] = useState([{type:'All Debitor'},{type:'Debitor Wise'},{type:'Location Wise'}]);
    let [report,reportSet] = useState([]);
    let [selectedType,selectedTypeSet] = useState({type:'All Debitor'});
    let [accounts,accountsSet] = useState([]);
    let [locations,locationsSet] = useState([]);
    let [vouchers,vouchersSet] = useState([]);
    
    let [reportLoading,reportLoadingSet] = useState(false);
    

    let [selectedAccount,selectedAccountSet] = useState(null)
    let [selectedLocation,selectedLocationSet] = useState(null)
    let [selectedVoucher,selectedVoucherSet] = useState(null)
  

    

    
    

    let [print,printSet] = useState(true);
    let [loadingAccounts,loadingAccountsSet] = useState(false);
    let [account_name,account_name_set] = useState('');

    let [balanceTotal,balanceTotalSet] = useState(0)
    let [po_no,po_no_set] = useState(0)
    let [po_amount,po_amount_set] = useState(0)
    
   
    useEffect(()=>{
        selectedAccountSet(null)
        selectedLocationSet(null)
    },[selectedType])



    useEffect(()=>{
          loadingAccountsSet(true)
          axios.post(`${API_URL}/api/get-accounts-by-search`,{query:account_name.trim(),type:'debitor'},{headers:{'auth-token':authInfo.token}}).then(res=>{
            accountsSet(res.data)
            loadingAccountsSet(false)
          })
          
    },[account_name])


    


    const getLocations = async ()=>{
      await axios.post(`${API_URL}/api/get-locations`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        locationsSet(res.data)
    })
    }

 
    useEffect(()=>{
          getDueVouchers();
      },[selectedAccount])


    let getDueVouchers = async ()=>{
        if(selectedAccount != null){
            await axios.post(`${API_URL}/api/get-due-sales-vouchers`,{customerId: selectedAccount != null ? selectedAccount.acc_id : 0},{headers:{'auth-token':authInfo.token}}).then(res=>{
                vouchersSet(res.data)
                });
        }
        
      }


    
    let getSearchResult = async ()=>{

      if(selectedType == null){
        swal({
          title:'Select Filter Type',
          icon:'warning'
        })
        return false
      }
     
        let url =  `${API_URL}/api/get-sundry-debitor-balance`;

         
            
        let reqPayload = {
            locationId: selectedLocation != null? selectedLocation.location_id:null,
            customerId: selectedAccount != null? selectedAccount.acc_id:null,
        }

        
        reportLoadingSet(true)
        await axios.post(`${url}`,{...reqPayload},{headers:{'auth-token':authInfo.token}}).then(res=>{
             printSet(true)
             reportLoadingSet(false)
            reportSet(res.data.accounts)
            balanceTotalSet(res.data.total_balance)
        })



    }


    let addToBill =  ()=>{

        if(selectedVoucher == null){
            swal({
                title:`Select a Voucher`,
                icon:'warning'
              })
              return false
        }
        reportSet([...report,selectedVoucher])

        selectedVoucherSet(null)

    }


    let getPoAmount = async (po)=>{
        await axios.post(`${API_URL}/api/get-po-amount`,{po_no:po},{headers:{'auth-token':authInfo.token}}).then(res=>{
            po_amount_set(res.data)
            });
    }


    let ReportDom = React.forwardRef( (props,ref) =>{
        return(
          <div ref={ref} >
                        <InstitutionProfile />

<div style={{clear:'both'}}></div>




{
    report.length > 0 && selectedAccount != null ?(<>
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
    <Grid item xs={12} sm={12}>
                                 <h3 className={"invoice-title"}>Bill</h3>
                            </Grid>

    <Grid container style={{marginBottom: '5px',fontSize:'12px',color:"#222",marginTop:'5px'}}>
                           <Grid item xs={6} sm={6} style={{textAlign:'left'}}> 
                                 <strong> Name : </strong> <span>{ selectedAccount.acc_name.toUpperCase() }</span><br/>
                                 <strong> Address : </strong> <span>{ selectedAccount.address }</span><br/>
                             
                            </Grid>
                            <Grid item xs={6} sm={6} style={{textAlign:'right'}}>
                            <strong> Contact Person : </strong> <span>{ selectedAccount.contact_person }</span><br/>
                            <strong> Contact No : </strong> <span>{ selectedAccount.contact_no }</span><br/>
                          
                            </Grid>
                      </Grid>


                         <Grid container>
                            <Grid item xs={12} sm={12}>
                              {
                                report.length != 0?(<>
                            <table className={'invoice-table'}>
                              <thead>

                              <tr>
                                          <td>PO No : {po_no}</td>
                                          <td>PO Amount : {po_amount}</td>
                                          <td colSpan={3}></td>
                                        </tr>


                                <tr>
                              <td>SL</td>
                                          <td>Bill No</td>
                                          <td>Bill Amount</td>
                                          <td>Paid Amount</td>
                                          <td style={{textAlign:'right'}}>Due Amount</td>
                                        </tr>
                              </thead>


                              <tbody>

                              
                                {
                                  report.map((bill,sl)=>(<>
                                  <tr>
                                          <td>{sl+parseFloat(1)}</td>
                                          <td>{bill.voucher_no}</td>
                                          <td>{bill.total_amount}</td>
                                          <td>{bill.paid}</td>
                                          <td>{bill.due}</td>
                                          </tr>

                                  </>))
                                }
                                       
                                       
                                        <tr>
                                          <td colSpan={2} style={{textAlign:'right'}}>Total : </td>
                                          <td>{format( report.reduce((prev,curr)=>{
                                            return prev+parseFloat(curr.total_amount)
                                          },0).toFixed(2))}</td>
                                          <td>{format(report.reduce((prev,curr)=>{
                                            return prev+parseFloat(curr.paid)
                                          },0).toFixed(2))}</td>
                                          <td>{format(report.reduce((prev,curr)=>{
                                            return prev+parseFloat(curr.due)
                                          },0).toFixed(2))}</td>
                                        </tr>
                                        
                              </tbody>
                                      

                                  </table>
                                </>):''
                              }
                            
                            </Grid>
                      </Grid> 
        </Paper>
  
    </>):''
    
}
    


      
     
<div style={{clear: 'both',height:'10px'}}></div>
<div  className="print-source invoice-footer-bottom" style={{bottom:'0px'}}>

{/* <Grid container>
                        <Grid item xs={4} sm={4}>
                        </Grid> 
                        <Grid item xs={4} sm={4}>
                        <p style={{fontSize:'12px',marginBottom:'15px',textAlign:'center',marginRight:'45px'}}>Attached Stamp <br/> & Signature</p>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                        </Grid>
                        </Grid> */}


<Grid container>
                        <Grid item xs={4} sm={4}>
                        <p style={{marginLeft:'20px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Prepared By</p>
                        </Grid>

                        <Grid item xs={4} sm={4}>
                        <p style={{textAlign:'center',marginLeft:'20px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}> Authorized By</p>
                        </Grid>

                        <Grid item xs={4} sm={4} style={{float:'right'}}>
                        <p style={{borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                        </Grid>

                        </Grid>

                        <p style={{textAlign:'center',marginBottom:'2px'}}>
                         " This is and electronically computer generated documents, does not require the signature or stamp "
                        </p>
                      </div>
          </div>
        )
    })




    const reportRef = useRef();

   

      return(
          <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{ textAlign: 'left',margin: "0px 0px  7px 0px",padding: '0px',color: '#222'}}>Bill  Report</h4>
<Grid container spacing={3} > 
   
         


          <Grid item  xs={12}   sm={3} > 
          <Autocomplete 
           autoHighlight
           size="small"
             openOnFocus={true}
             style={{width:'100%',height:'20px'}}
             options={accounts}
             loading={loadingAccounts}
             value={selectedAccount}
             getOptionLabel={(option) => option.acc_name}
             onChange={(event,selectedObj)=>{
                selectedAccountSet(selectedObj) 
             }}
             renderInput={(params) => <TextField 
              label="Search Customer/ Debtor " 
               onChange={e => account_name_set(e.target.value)} 
               {...params} 
               InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingAccounts ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
               variant="outlined"
               />} 
          
      />
          </Grid>


          <Grid item  xs={12}   sm={3} > 
          <Autocomplete 
           autoHighlight
           size="small"
             openOnFocus={true}
             style={{width:'100%',height:'20px'}}
             options={vouchers}
             value={selectedVoucher}
             getOptionLabel={(option) => option.voucher_no}
             onChange={(event,selectedObj)=>{
                selectedVoucherSet(selectedObj) 
             }}
             renderInput={(params) => <TextField 
              label="Select Voucher" 
               {...params} 
               InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingAccounts ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
               variant="outlined"
               />} 
          
      />
          </Grid>




          <Grid item xs={12} sm={2}  style={{marginBottom:'10px'}}>
            <TextField type="text"  
           
           autoComplete='off' className={classes.fullWidth} 
         label="PO No" name="po_no" value={po_no} 
         variant="outlined" size="small" onChange={(e)=>{
            po_no_set(e.target.value)
            getPoAmount(e.target.value)
         }
         } />       
            </Grid>

            <Grid item xs={12} sm={2}  style={{marginBottom:'10px'}}>
            <TextField type="text"  
            onKeyDown={(e)=>{
          
            }}
           autoComplete='off' className={classes.fullWidth} 
         label="PO Amount" name="po_amount" value={po_amount} 
         variant="outlined" size="small" onChange={(e)=>po_amount_set(e.target.value)} />
               
                         
            </Grid>



          <Grid item  xs={12}   sm={2} >
        <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SearchIcon/>}
                            onClick={addToBill}
                        >
                        Add To Bill
                      </Button>
        </Grid>
        


          </Grid>


        </Paper>
        {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-17px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }
      
            <ReportDom ref={reportRef} /> 




          </div>
      )
}



const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        color: 'white',
        fontSize:'10px',
        backgroundColor: '#115f5f'
      },
    
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(SalesRecord); 
