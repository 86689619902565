import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {acc_types} from '../../account_types.json';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';


import DateFnsUtils from '@date-io/date-fns';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import moment from 'moment';

import { useHistory } from "react-router-dom";


import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {pathSpliter,dateTimeFormat,checkIntNum,currentDateTime,getDateTimeFromISODT,dateFormat} from '../../lib/functions'


import {currentRouteSet} from '../../actions/actions';


const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getAccounts();
    getLocations()
    getEmployees()
    getAccCode()
    currentRouteSet(pathSpliter(location.pathname,1));
    if(pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0 != 0){
      getReport()

    }
  },[])

    // Init states start
    const history = useHistory();

    
    let [accounts,accountsSet] = useState([])
    let [customers,customersSet] = useState([])
    let [locations,locationsSet] = useState([])
    let [employees,employeesSet] = useState([])
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)
    let [selectedAccountType,selectedAccountTypeSet] = useState(null)
    let [report_no,report_no_set] = useState('')

    let [amc,amc_set] = useState(false)
    let [po_wo,po_wo_set] = useState(false)
    let [under_warranty,under_warranty_set] = useState(false)
    let [on_call_service,on_call_service_set] = useState(false)

    
    

    let [action,action_set] = useState('create');
    let [id,id_set] = useState(0);
    let [cus_name,cus_name_set] = useState('');
    let [cus_address,cus_address_set] = useState('');
    let [req_cus_name,req_cus_name_set] = useState('');
    let [req_cus_designation,req_cus_designation_set] = useState('');
    let [req_cus_cell,req_cus_cell_set] = useState('');
    let [ref_no,ref_no_set] = useState('');
    let [ser_requirements,ser_requirements_set] = useState('');
    let [ser_details,ser_details_set] = useState('');
    let [recommend,recommend_set] = useState('');
    let [cus_comment,cus_comment_set] = useState('');
    let [cus_repre_name,cus_repre_name_set] = useState('');
    let [cus_repre_designation,cus_repre_designation_set] = useState('');
    let [ser_for,ser_for_set] = useState('');

    let [service_by_name,service_by_name_set] = useState('');
    let [service_by_designation,service_by_designation_set] = useState('');
    let [service_by_id,service_by_id_set] = useState('');
 
    let [selectedEmployee,selectedEmployeeSet] = useState(null);
    
    
    const [ser_date, ser_date_set] = useState(currentDateTime);

 

    
    const getReport = async ()=>{
      await axios.post(`${API_URL}/api/get-report-record`,{id : pathSpliter(location.pathname,3)},{headers:{'auth-token':authInfo.token}}).then(async res=>{
        let data = res.data[0];



        id_set(data.id)
        cus_name_set(data.cus_name)
        cus_address_set(data.cus_address)
        req_cus_name_set(data.req_cus_name)
        req_cus_designation_set(data.req_cus_designation)
        req_cus_cell_set(data.req_cus_cell)
        ref_no_set(data.ref_no)
        ser_requirements_set(data.ser_requirements)
        ser_details_set(data.ser_details)
        recommend_set(data.recommend)
        cus_comment_set(data.cus_comment)
        cus_repre_name_set(data.cus_repre_name)
        cus_repre_designation_set(data.cus_repre_designation)
        report_no_set(data.report_no)
        service_by_name_set(data.service_by_name)
        service_by_designation_set(data.service_by_designation)
        service_by_id_set(data.service_by_id)
        po_wo_set( data.po_wo == '0'?false:true)
        under_warranty_set(data.under_warranty)
        on_call_service_set(data.on_call_service)
        amc_set(data.amc)

       
        action_set('update')


        
      })
    }

    const handleDateChange = (date) => {
        ser_date_set(date);
      };

    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{
       
            loadingSaveSet(true);

            await axios.post(`${API_URL}/api/save-report-entry`,{
              cus_name:cus_name.trim(),
              ser_date,
              ser_for,
              cus_address,
              req_cus_name,
              req_cus_designation,
              req_cus_cell,
              ref_no,
              ser_requirements,
              ser_details,
              recommend,
              cus_comment,
              cus_repre_name,
              cus_repre_designation,
              report_no,
              service_by_name,
              service_by_designation,
              service_by_id,
              po_wo,
              under_warranty,
              on_call_service,
              amc,
              action,
              id
            },{headers:{'auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
          
              if(res.data.error){
                swal({
                  title: res.data.msg,
                  icon:'warning'
                })
              }else{
               
                  swal({title:`Created Successfully.  Do you want to view invoice?`,icon:'success',buttons:true}).then(confirm=>{
                    if(confirm){
                      history.push(`/service-report/quick-service-voucher/${res.data.id}`)
                     
                    }else{
                      window.location.reload()
                    }
                  })
                

             



              }
              
            });
          
          
    }

    let [defaults,defaultsSet] = useState(['Discount on purchase','discount on service','Vat & Tax Account','Discount on sale',
                                'Sales','Sales Return','Discount on service','Purchase','Purchase Return','Salary','Services','Service Expense'])


    const getAccCode = async ()=>{
      await axios.post(`${API_URL}/api/get-service-code`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        report_no_set(res.data)
      })
    }

  
    const getAccounts = async ()=>{
      await axios.post(`${API_URL}/api/get-accounts`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        accountsSet(res.data)
      })
    }

    const getLocations = async ()=>{
      await axios.post(`${API_URL}/api/get-locations`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        locationsSet(res.data)
      })
    }


    const getEmployees = async ()=>{
      await axios.post(`${API_URL}/api/get-employees`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        employeesSet(res.data)
      })
    }



    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>accountEdit(props.rowData)}/>
      
        {/* <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>accountDelete(props.rowData)}/> */}
            
   </>):''
 }
      
      </div>)
    
    }


    const accountEdit = (row,index)=>{
      let account =  accounts.filter((account)=>account.id == row[0])

      // if(account[0].cus_name.trim().toLowerCase() == 'cash'){
      //   swal({
      //     title: `This Account  Can't be Edit.`,
      //     icon:'warning'
      //   });
      //   return false
      // }

      
      let result =   defaults.findIndex((acc)=>{
        let real = acc.trim().toLowerCase()
        let check = account[0].cus_name.trim().toLowerCase()
          return real == check
        })

     if(result > -1){
      swal({
        title: `This Account  Can't be Edit.`,
        icon:'warning'
      });
      return false
     }


      id_set(account[0].id)
      cus_name_set(account[0].cus_name)
      action_set('update')


      selectedEmployeeSet({employee_name:account[0].employee_name,employee_id:account[0].employee_id})
   
      report_no_set(account[0].report_no)

      



    }
    const [customer_name, customer_name_set] = useState("");



    useEffect(()=>{
        getCustomers()
        },[customer_name])


    const getCustomers = async() => {
        await axios.post(`${API_URL}/api/get-accounts-by-search`,{query:customer_name.trim(),type:'debitor'},{headers:{'auth-token':authInfo.token}}).then(res=>{
       
         let newArray = res.data;
          customersSet(newArray)
   
         
   
   
       })
   };
   


    const accountDelete = async (row)=>{

      let account =  accounts.filter((account)=>account.id == row[0])


      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{

        

      let result =   defaults.findIndex((acc)=>{
        let real = acc.trim().toLowerCase()
        let check = account[0].cus_name.trim().toLowerCase()
          return real == check
        })

     if(result > -1){
      swal({
        title: `This Account  Can't be Deleted.`,
        icon:'warning'
      });
      return false
     }
    
        if(yes){
          await axios.post(`${API_URL}/api/delete-account`,{id:account[0].id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getAccounts();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "id",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "cus_name",label: "Account Name",options: {filter: true,sort: true}},
      {name: "acc_type_name",label: "Account Type",options: {filter: true,sort: true}},
      {name: "report_no",label: "Account Code",options: {filter: true,sort: true}},
      {name: "opening_balance",label: "Opening Balance",options: {filter: true,sort: true}},

      {name: "creation_date",label: "Creation Date",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ moment(tableMeta.rowData[5]).format(dateFormat) }</p>) 
        }
      }
      
    },

      
      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }




    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Customer Service Entry </h2>

<Grid container>


      <Grid item xs={12} sm={4}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          // inputRef={cus_name_ref}
          // onKeyDown={event => {
          //   if (event.key === "Enter") {
          //     acc_type_ref.current.focus()
          //   }
          // }}
        label="Service Code" name="report_no" value={report_no} variant="outlined" size="small" onChange={(e)=>report_no_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={4}></Grid>

        <Grid item xs={12} sm={4} style={{marginTop:'-20px'}}> 
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
       
        <KeyboardDatePicker
          margin="normal"
          label="Service Date"
          format="MM/dd/yyyy"

     
          value={ser_date}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        
    
    </MuiPickersUtilsProvider>
        </Grid>

        </Grid>

          <h3 style={{textAlign:'left'}}>CSR ISSUED FOR : </h3>
        <Grid container>
            <Grid item xs={12} sm={12}> 
            <TextField  autoComplete='off' style={{marginBottom:'8px'}} className={classes.fullWidth} 
        
            label="Customer Name" name="cus_name" value={cus_name} variant="outlined" size="small" onChange={(e)=>cus_name_set(e.target.value)} />
            </Grid>

            <Grid item xs={12} sm={12}> 
        <TextareaAutosize  value={cus_address}  name="cus_address" onChange={(e)=>cus_address_set(e.target.value)} style={{float:'left',marginTop:'',width: '100%'}} aria-label="Address" 
         rowsMin={3} placeholder="Address" />
        </Grid>
       

        </Grid>

      
        

        <h3 style={{textAlign:'left'}}>Requested by (customer) : </h3>
        
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} spacing={2}>
            <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
        <TextField  autoComplete='off'  className={classes.fullWidth} 
       
        label=" Name" name="req_cus_name" value={req_cus_name} variant="outlined" 
        size="small" onChange={(e)=>req_cus_name_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
        <TextField  autoComplete='off'  className={classes.fullWidth} 
       
        label="Designation" name="req_cus_designation" value={req_cus_designation} variant="outlined" 
        size="small" onChange={(e)=>req_cus_designation_set(e.target.value)} />
        </Grid>


        <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
       
        label="Cell" name="req_cus_cell" value={req_cus_cell} variant="outlined" 
        size="small" onChange={(e)=>req_cus_cell_set(e.target.value)} />
        </Grid>

            </Grid>

            <Grid item xs={12} sm={6}>
            <h3 style={{textAlign:'left'}}>JOB REFERENCE : </h3>
              
            <label>
      <input type="checkbox"
        defaultChecked={amc}
        onChange={() => amc_set(!amc)}
      />
      
      <span className='checktitle'>AMC</span>

    </label>

    <label>
      <input type="checkbox"
        defaultChecked={po_wo}
        onChange={() => po_wo_set(!po_wo)}
      />
      
      <span className='checktitle'>PO/WO</span>

    </label>

    <label>
      <input type="checkbox"
        defaultChecked={under_warranty}
        onChange={() => under_warranty_set(!under_warranty)}
      />
      <span className='checktitle'>Under Warranty</span>

    </label>

    <br/>

   


    <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
       
        label="Ref No" name="ref_no" value={ref_no} variant="outlined" 
        size="small" onChange={(e)=>ref_no_set(e.target.value)} />
        </Grid>


        <label>
      <input type="checkbox"
        defaultChecked={on_call_service}
        onChange={() => on_call_service_set(!on_call_service)}
      />
      
      <span className='checktitle'>On call Service</span>

    </label>

             </Grid>


             
             
            
       
        </Grid>



        <Grid container>
            <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
            
            <TextareaAutosize  value={ser_requirements}  name="ser_requirements" onChange={(e)=>ser_requirements_set(e.target.value)} style={{float:'left',marginTop:'',width: '100%'}} aria-label="Sevice Requirements" 
                rowsMin={6} placeholder="Sevice Requirements" />
            </Grid>
        </Grid>


        <Grid container>
            <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
            
            <TextareaAutosize  value={ser_details}  name="ser_details" onChange={(e)=>ser_details_set(e.target.value)} style={{float:'left',marginTop:'',width: '100%'}} aria-label="Service Detail" 
         rowsMin={6} placeholder="Service Details" />
            </Grid>
        </Grid>


        <Grid container>
            <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
            
            <TextareaAutosize  value={recommend}  name="recommend" onChange={(e)=>recommend_set(e.target.value)} style={{float:'left',marginTop:'',width: '100%'}} aria-label="Recommend" 
         rowsMin={6} placeholder="Recommendation of future work" />
            </Grid>
        </Grid>


        <Grid container>
            <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
            
           
        <TextareaAutosize  value={cus_comment}  name="cus_comment" onChange={(e)=>cus_comment_set(e.target.value)} style={{float:'left',marginTop:'',width: '100%'}} aria-label="customer comment" 
         rowsMin={6} placeholder="Comments of Customer End." /> 

            </Grid>
        </Grid>



        <Grid container spacing={2} style={{border:'1px solid #222'}}>
           <Grid item xs={12} sm={6}>
           <h2 style={{textAlign:'left'}}>Service By : {authInfo.userInfo.branch_name} </h2>

               <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Name" name="service_by_name" value={service_by_name} variant="outlined" 
                size="small" onChange={(e)=>service_by_name_set(e.target.value)} />
                </Grid>

                <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Designation" name="service_by_designation" value={service_by_designation} variant="outlined" 
                size="small" onChange={(e)=>service_by_designation_set(e.target.value)} />
                </Grid>

                <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="ID No" name="service_by_id" value={service_by_id} variant="outlined" 
                size="small" onChange={(e)=>service_by_id_set(e.target.value)} />
                </Grid>


           </Grid>

           <Grid item xs={12} sm={6}>
           <h2 style={{textAlign:'left'}}>For customer Representative : </h2>

               <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Name" name="cus_repre_name" value={cus_repre_name} variant="outlined" 
                size="small" onChange={(e)=>cus_repre_name_set(e.target.value)} />
                </Grid>

                <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Designation" name="cus_repre_designation" value={cus_repre_designation} variant="outlined" 
                size="small" onChange={(e)=>cus_repre_designation_set(e.target.value)} />
                </Grid>

           


           </Grid>
        </Grid>


  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

    

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
      position:'relative'  
},
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);