import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import {API_URL} from '../../config.json';
import InvoiceBody from './service_body'
import {currentDateTime,convertNumberToWords, dateTimeFormat,dateFormat} from '../../lib/functions'
import PrintIcon from '@material-ui/icons/Print';
import moment from 'moment';
import ReactToPrint from "react-to-print";
import Font, {Text} from 'react-font'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import '../commons/voucher.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

class PrintAbleSection extends React.Component {
      
  constructor(props) {
    super(props); 


  }

  state = {
    sales:[],
    prevBills:[],
    institution:null,
    accDue:0,
  }

  componentDidMount() {
    this.getSalesOrder()
    this.setState({institution:this.props.institution});
    
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sale_id != this.props.sale_id) {
        this.getSalesOrder()
    }

  }
   
   getSalesOrder = async()=>{
      let accId = null
      await axios.post(`${API_URL}/api/get-report-record`,{id:this.props.sale_id,from:'voucher'},{headers:{'auth-token':this.props.authInfo.token}}).then(async res=>{
       if(res.data.length == 0) return false


        this.setState({sales:res.data[0]});
        
      });

   

      
  }



  render(){
    let {sales,institution,accDue,prevBills}  = this.state;
    return(
      <div style={{padding:'20px',paddingBottom:'0px'}} >
        {
          sales.length!=0?(
              <>
                <Grid container>
                            <Grid item xs={12} sm={12}>
                                 <h3 className={"invoice-title"} style={{fontSize:'30px'}}>CUSTOMER SERVICE REPORT</h3>
                            </Grid>
                      </Grid>



                      <Grid container style={{marginBottom: '5px',fontSize:'12px',color:"#222",marginTop:'5px'}}>
                           <Grid item xs={6} sm={6}> 
                                 {/* <strong>Customer/ Debtor Code : </strong> <span>{ sales.acc_code }</span><br/> */}
                                 <p style={{fontSize:'18px',fontWeight:'bold',margin:'8px'}}> CSR NO : { sales.report_no }</p>
                               
                            </Grid>
                            <Grid item xs={6} sm={6} style={{textAlign:'right'}}>
                            <p style={{fontSize:'18px',fontWeight:'bold',margin:'8px'}}>CSR ISSUE DATE  : { moment(sales.ser_date).format(dateFormat)  } </p>
                             

                            </Grid>

                         

                      </Grid>

                      <Grid container>
                            <Grid item xs={12}> 
                            <p style={{fontSize:'18px',fontWeight:'bold',margin:'8px'}}>CSR ISSUE FOR : </p>
                            <p style={{fontSize:'18px',fontWeight:'bold',margin:'8px'}}>CUSTOMER NAME : {sales.cus_name} </p>
                            <p style={{fontSize:'18px',fontWeight:'bold',margin:'8px'}}>ADDRESS : {sales.cus_address} </p>
                            </Grid>
                      </Grid>


                      <Grid container>
                            <Grid item xs={6}> 
                            <p style={{fontSize:'25px',fontWeight:'bold',margin:'8px',background:'#b4b4b4'}}>REQUEST BY : (CUSTOMER) </p>
                            <p style={{fontSize:'18px',fontWeight:'bold',margin:'8px'}}>NAME : {sales.req_cus_name} </p>
                            <p style={{fontSize:'18px',fontWeight:'bold',margin:'8px'}}>DESIGNATION : {sales.req_cus_designation} </p>
                            <p style={{fontSize:'18px',fontWeight:'bold',margin:'8px'}}>CELL : {sales.req_cus_cell} </p>

                            </Grid> 

                            <Grid item xs={6}> 
                            <p style={{fontSize:'25px',fontWeight:'bold',margin:'8px',background:'#b4b4b4'}}>JOB REFERENCE </p>
                            
                            <b>{sales.amc  == "1" ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />} AMC</b>    <b>{sales.po_wo == "1" ? <CheckBoxIcon /> :<CheckBoxOutlineBlankIcon />} PO/ WO</b>  <b>{sales.under_warranty  == "1" ? <CheckBoxIcon />  :<CheckBoxOutlineBlankIcon />} UNDER  WARRANTY</b>
                            <p style={{fontSize:'25px',fontWeight:'bold',margin:'8px',background:''}}> REFERENCE  NO : {sales.ref_no} </p>
                            <b>{sales.on_call_service  == "1" ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />} ON CALL SERVICE</b>
                            </Grid>
                      </Grid>

                      <Grid container>
                            <Grid item xs={12}> 
                            <p style={{fontSize:'25px',fontWeight:'bold',margin:'8px',background:'rgb(202 202 202)'}}>SERVICE REQUIREMENTS : </p>
                            <p style={{fontSize:'18px',fontWeight:'NORMAL',margin:'8px',minHeight:'25px'}}>{sales.ser_requirements}</p>
                            </Grid> 
                      </Grid>


                      <Grid container>
                            <Grid item xs={12}> 
                            <p style={{fontSize:'25px',fontWeight:'bold',margin:'8px',background:'rgb(202 202 202)'}}>SERVICE DETAILS : </p>
                            <p style={{fontSize:'18px',fontWeight:'NORMAL',margin:'8px',minHeight:'25px'}}>{sales.ser_details}</p>
                            </Grid> 
                      </Grid>

                      <Grid container>
                            <Grid item xs={12}> 
                            <p style={{fontSize:'25px',fontWeight:'bold',margin:'8px',background:'rgb(202 202 202)',textTransform: 'uppercase'}}>recommendation of future work : </p>
                            <p style={{fontSize:'18px',fontWeight:'NORMAL',margin:'8px',minHeight:'25px'}}>{sales.recommend}</p>
                            </Grid> 
                      </Grid>

                      <Grid container>
                            <Grid item xs={12}> 
                            <p style={{fontSize:'25px',fontWeight:'bold',margin:'8px',background:'rgb(202 202 202)',textTransform: 'uppercase'}}>COMMENTS OF CUSTOMER END : </p>
                            <p style={{fontSize:'18px',fontWeight:'NORMAL',margin:'8px',minHeight:'25px'}}>{sales.cus_comment}</p>
                            </Grid> 
                      </Grid>
                 

                      <Grid container>
                            <Grid item xs={6} style={{border:'2px solid #222'}}> 
                            <p style={{fontSize:'25px',fontWeight:'bold',margin:'8px',background:'rgb(202 202 202)',textTransform: 'uppercase'}}>SERVICE BY : {this.props.authInfo.userInfo.branch_name} </p>
                            <p style={{fontSize:'18px',fontWeight:'bold',margin:'8px',minHeight:'25px'}}>NAME : {sales.service_by_name}</p>
                            <p style={{fontSize:'18px',fontWeight:'bold',margin:'8px',minHeight:'25px'}}>DESIGNATION : {sales.service_by_designation}</p>

                            </Grid> 

                            <Grid item xs={6} style={{border:'2px solid #222'}}> 
                            <p style={{fontSize:'25px',fontWeight:'bold',margin:'8px',background:'rgb(202 202 202)',textTransform: 'uppercase'}}> FOR CUSTOMER REPRESENTATIVE : </p>
                            <p style={{fontSize:'18px',fontWeight:'bold',margin:'8px',minHeight:'25px'}}>NAME :{sales.cus_repre_name}</p>
                            <p style={{fontSize:'18px',fontWeight:'bold',margin:'8px',minHeight:'25px'}}>DESIGNATION :{sales.cus_repre_designation}</p>

                            </Grid> 


                      </Grid>
                            

                
              </>

          ):''
        }
         
      </div>
    )
  }
}


class ComponentToPrint extends React.Component {

   
  constructor(props) {
    super(props);  
     }
  state = {
    authInfo:this.props.authInfo,
    sale_id:this.props.sale_id,
    institution:this.props.institution,
  }


  render() {
      let institution =   this.props.institution;
      let a4css = `
      .a4 {
        font-size: 12px;
        
    }
    .a4 body, table{
        font-size: 12px;
    }
      `
  
      let hafa4css = `
      .hafa4 { 
        width:500px !important;
    }
    .hafa4 body, table{
        font-size: 12px;
    }
      `
  
      let poscss = `
      .pos {
        width: 219px !important;
      }
      .pos body{
        font-size: 10px;
      }
    .pos body, table{
        font-size: 10px;
    }
      `
   
    return (
      <div className='print-source' >
           {/* Print  DOCUMENT */}

                 {/* {   A4 Print */
                    institution != null &&  institution.pro_print_type == 'a4'?(
                      <html lang="en">
                    <head>
                       <meta charset="UTF-8" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                        <title>SALES  VOUCHER</title>
                        <style >
                           {a4css}
                        </style>
                    </head>
                    <tbody className="a4" style={{padding:'5px'}}>



                     

                     <table style={{width:'100%'}}>

                       <thead>
                          <tr>
                            <td><div style={{height:'10px'}}></div></td>
                            </tr>
                       </thead>

                       <tbody>
                         <tr>
                           <td>
                            
                        <div className="invoice-head" style={{width:'100%'}}>
                            <div className="invoice-logo" style={{width:'15%',float:'left',marginLeft:'0%',marginTop:'20px',overflow:'hidden'}}>
                                  <image alt="logo" src={`${API_URL}/${institution.pro_logo}`} style={{width:'80px',height:'80px'}} />
                            </div>
                            <div style={{width:'2%'}}></div>
                            <div className="invoice-desc" style={{width:'50%',float:'left',marginLeft:'5px'}}>
                                  <h2 style={{textAlign:'center'}}>{institution.pro_name}</h2>
                                  <Font family='Roboto'  sans-serif>
                                  <p style={{textAlign:'center',color:'#222',fontSize:'16px',margin:'0px',padding:'0px',whiteSpace:'pre-line'}}>{institution.pro_desc}</p>

                                  </Font>
                            </div>
                            <div style={{width:'2%'}}></div>

                            <div className="invoice-desc" style={{width:'30%',float:'right',marginRight:'5px',marginTop:'55px',border:'1px solid #222',padding:'3px',display:'inherit'}}>
                                  <p style={{textAlign:'left',color:'#222',fontSize:'16px',margin:'0px',padding:'0px',whiteSpace:'pre-line'}}>{institution.company_info}</p>

                            </div>
                        </div>
                           </td>
                           </tr>
                         <tr>
                           <td>
                           <PrintAbleSection   authInfo={this.state.authInfo} sale_id={this.props.sale_id} institution={institution} />

                           </td>
                           
                         </tr>


                         <tr>
                           <td>
                           <div className="invoice-footer-bottom"  >
                      <div className="invoice-footer" style={{marginTop:'10px'}}>

                        <Grid container>
                        <Grid item xs={4} sm={4}>
                        </Grid> 
                        <Grid item xs={4} sm={4}>
                        <p style={{fontSize:'12px',marginBottom:'25px',textAlign:'center',marginRight:'45px'}}>Attached Stamp <br/> & Signature</p>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                        </Grid>
                        </Grid>

                        <Grid container>
                        <Grid item xs={4} sm={4}>
                        <p style={{marginLeft:'20px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Prepared By</p>
                        </Grid>

                        <Grid item xs={4} sm={4}>
                        <p style={{textAlign:'center',marginLeft:'20px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}> Authorized By</p>
                        </Grid>

                        <Grid item xs={4} sm={4} style={{float:'right'}}>
                        <p style={{borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                        </Grid>

                        </Grid>

                        <p style={{textAlign:'center',marginBottom:'2px'}}>
                         " This is and electronically computer generated documents, does not require the signature or stamp "
                        </p>

                           
                      </div>


                      <div className="invoice-footer" >
                       
                            <p style={{float:'left',fontSize:'12px',marginLeft:'20px',color:'#222',fontWeight:'bold'}}>
                                Print Date  :  {moment().format(dateFormat)} 
                           </p>
                           <p style={{float:'right',marginRight:'12px',marginRight:'25px',fontSize:'10px',color:'#222',fontWeight:'bold',padding:'0px'}}>
                                Developed By : Soft Task
                           </p>
                      </div>
                      
                      </div>
                           </td>
                         </tr>
                       </tbody>

                       <tfoot >
                         <tr>
                         <td>
                           <div style={{height:'100px'}}></div>
                           </td>
                         </tr>
                       </tfoot>
                     </table>

                      
                    </tbody>
                    </html>
                     ):''
                 }
                    


                  {/* {  1/2 - A4   Print */
                    institution != null &&  institution.pro_print_type == '1/2a4'?(
                      <html lang="en">
                      <head>
                         <meta charset="UTF-8" />
                          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                          <title>SALES  VOUCHER</title>
                          <style >
                             {hafa4css}
                          </style>
                      </head>
                      <tbody  className="hafa4" style={{padding:'5px'}}>

                       <table style={{width:'100%'}}>
  
                         <thead>
                            <tr>
                              <td><div style={{height:'10px'}}></div></td>
                              </tr>
                         </thead>
  
                         <tbody>
                           <tr>
                             <td>
                              
                          <div className="invoice-head" style={{width:'100%'}}>
                              <div className="invoice-logo" style={{width:'20%',float:'left',marginLeft:'0%',marginTop:'20px',overflow:'hidden'}}>
                                    <image alt="logo" src={`${API_URL}/${institution.pro_logo}`} style={{width:'100px',height:'100px'}} />
                              </div>
                              <div style={{width:'5%'}}></div>
                              <div className="invoice-desc" style={{width:'75%',float:'left',marginLeft:'5px'}}>
                                    <h2 style={{textAlign:'center'}}>{institution.pro_name}</h2>
                                    <Font family='Roboto'  sans-serif>
                                    <p style={{textAlign:'center',color:'#222',fontSize:'15px',margin:'0px',padding:'0px'}}>{institution.pro_desc}</p>
  
                                    </Font>
                              </div>
                          </div>
                             </td>
                             </tr>
                           <tr>
                             <td>
                             <PrintAbleSection   authInfo={this.state.authInfo} sale_id={this.props.sale_id} institution={institution} />
  
                             </td>
                             
                           </tr>
  
  
                           <tr>
                             <td>
                             <div className="invoice-footer-bottom "  >
                        <div className="invoice-footer" style={{marginTop:'60px'}}>
                                  <p style={{float:'left',marginLeft:'20px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                             
                                   <p style={{float:'right',marginRight:'25px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                        </div>
  
  
                        <div className="invoice-footer " >
                         
                              <p style={{float:'left',fontSize:'12px',marginLeft:'20px',color:'#222',fontWeight:'bold'}}>
                                  Print Date  :  {moment().format(dateFormat)} 
                             </p>
                             <p style={{float:'right',marginRight:'12px',marginRight:'25px',fontSize:'10px',color:'#222',fontWeight:'bold',padding:'0px'}}>
                                  Developed By : Soft Task 
                             </p>
                        </div>
                        
                        </div>
                             </td>
                           </tr>
                         </tbody>
  
                         <tfoot >
                           <tr>
                           <td>
                             <div style={{height:'100px'}}></div>
                             </td>
                           </tr>
                         </tfoot>
                       </table>
  
                        
                      </tbody>
                      </html>
                     ):''
                 }


                  {/* {   A4 Print */
                    institution != null &&  institution.pro_print_type == 'pos'?(
                      <html lang="en">
                      <head>
                         <meta charset="UTF-8" />
                          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                          <title>SALES  VOUCHER</title>
                          <style >
                             {poscss}
                          </style>
                      </head>
                      <tbody  className="pos" style={{padding:'5px'}}>

                       <table  className="pos">
  
                         <thead>
                            <tr>
                              <td><div style={{height:'10px'}}></div></td>
                              </tr>
                         </thead>
  
                         <tbody>
                           <tr>
                             <td>
                              
                          <div className="invoice-head pos">
                              <div className="invoice-logo" style={{width:'35%',float:'left',marginLeft:'0%',marginTop:'20px',overflow:'hidden'}}>
                                    <image alt="logo" src={`${API_URL}/${institution.pro_logo}`} style={{width:'100px',height:'100px'}} />
                              </div>
                              <div style={{width:'5%'}}></div>
                              <div className="invoice-desc" style={{width:'60%',float:'right',marginLeft:'5px',textAlign:'center'}}>
                                    <h2 style={{textAlign:'center'}}>{institution.pro_name}</h2>
                                    <Font family='Roboto'  sans-serif>
                                    <p style={{textAlign:'center',color:'#222',fontSize:'12px',margin:'0px',padding:'0px'}}>{institution.pro_desc}</p>
  
                                    </Font>
                              </div>
                          </div>
                             </td>
                             </tr>
                           <tr>
                             <td>
                             <PrintAbleSection   authInfo={this.state.authInfo} sale_id={this.props.sale_id} institution={institution} />
  
                             </td>
                             
                           </tr>
  
  
                           <tr>
                             <td>
                             <div className="invoice-footer-bottom pos"  >
                        <div className="invoice-footer" style={{marginTop:'60px'}}>
                             
                                   <p style={{float:'right',marginRight:'25px',borderTop:'1px solid #222',width:'150px',textAlign:'right'}}>Authorized By</p>
                        </div>
  
  
                        <div className="invoice-footer " >
                         
                              <p style={{float:'left',fontSize:'12px',marginLeft:'20px',color:'#222',fontWeight:'bold',margin:'0px'}}>
                                  Print Date  :  {moment().format(dateFormat)} 
                             </p>
                             <p style={{float:'left',marginRight:'12px',marginRight:'25px',fontSize:'10px',color:'#222',fontWeight:'bold',padding:'0px',margin:'0px'}}>
                                  Developed By : Soft Task 
                             </p>
                        </div>
                        
                        </div>
                             </td>
                           </tr>
                         </tbody>
  
                         <tfoot >
                           <tr>
                           <td>
                             <div style={{height:'100px'}}></div>
                             </td>
                           </tr>
                         </tfoot>
                       </table>
  
                        
                      </tbody>
                      </html>
                     ):''
                 }
                    
           
      </div>
    );
  }
}

let SalesVoucher = ({authInfo,sale_id,institution})=>{
  let componentRef = useRef()
  return (
    <>
            <Grid container>
                  <Grid item xs={12} sm={11} className={"invoice-section"}>
                    {/* Main Grid Start */}
                    <div>
                    <ReactToPrint
                      trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '18px'}} />}
                      content={() => componentRef}
                      onBeforePrint = {() => componentRef}
                    />

    <ComponentToPrint ref={el => (componentRef = el)} authInfo={authInfo} sale_id={sale_id} institution={institution} />


                    <PrintAbleSection  authInfo={authInfo} sale_id={sale_id} institution={institution} />

                  </div>
                  </Grid>
            </Grid>
    </>
  )
}



  const mapStateToPops = (state)=>{
    return {
      authInfo:state.authInfoReducer
    }
}
export default connect(mapStateToPops,{})(SalesVoucher);
