import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {acc_types} from '../../account_types.json';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import '../commons/table.css'


import DateFnsUtils from '@date-io/date-fns';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import moment from 'moment';

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';



import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {pathSpliter,dateTimeFormat,checkIntNum,currentDateTime,getDateTimeFromISODT,dateFormat} from '../../lib/functions'


import {currentRouteSet} from '../../actions/actions';
import { et } from 'date-fns/locale';


const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getAccounts();
    getLocations()
    getEmployees()
    getAccCode()
    currentRouteSet(pathSpliter(location.pathname,1));

    if(pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0 != 0){
      getHandOver()

    }
    
  },[])

    // Init states start
 
    
    let [accounts,accountsSet] = useState([])
    let [customers,customersSet] = useState([])
    let [locations,locationsSet] = useState([])
    let [employees,employeesSet] = useState([])
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)
    let [selectedAccountType,selectedAccountTypeSet] = useState(null)
    let [phf_no,phf_no_set] = useState('')

    let [amc,amc_set] = useState(false)
    let [po_wo,po_wo_set] = useState(false)
    let [under_warranty,under_warranty_set] = useState(false)
    let [on_call_service,on_call_service_set] = useState(false)

    
    

    let [action,action_set] = useState('create');
    let [id,id_set] = useState(0);
    let [project_name,project_name_set] = useState('');
    let [main_customer,main_customer_set] = useState('');
    let [supplier_name,supplier_name_set] = useState('');
    let [supplier_mobile,supplier_mobile_set] = useState('');
    let [supplier_email,supplier_email_set] = useState('');

    let [installer_name,installer_name_set] = useState('');
    let [installer_email,installer_email_set] = useState('');
    let [installer_mobile,installer_mobile_set] = useState('');

    let [com_engineer_name,com_engineer_name_set] = useState('');
    let [com_engineer_mobile,com_engineer_mobile_set] = useState('');
    let [com_engineer_email,com_engineer_email_set] = useState('');

    let [coordinator_name,coordinator_name_set] = useState('');
    let [coordinator_mobile,coordinator_mobile_set] = useState('');
    let [coordinator_email,coordinator_email_set] = useState('');

    let [head_res_name,head_res_name_set] = useState('');
    let [head_res_mobile,head_res_mobile_set] = useState('');
    let [head_res_email,head_res_email_set] = useState('');
    
    let [project_address,project_address_set] = useState('');
    let [req_project_name,req_project_name_set] = useState('');
    let [req_cus_designation,req_cus_designation_set] = useState('');
    let [req_cus_cell,req_cus_cell_set] = useState('');
    let [ref_no,ref_no_set] = useState('');
    let [ser_requirements,ser_requirements_set] = useState('');
    let [ser_details,ser_details_set] = useState('');
    let [recommend,recommend_set] = useState('');
    let [cus_comment,cus_comment_set] = useState('');
    let [cus_repre_name,cus_repre_name_set] = useState('');
    let [cus_repre_designation,cus_repre_designation_set] = useState('');
    let [res_name,res_name_set] = useState('');
    let [res_designation,res_designation_set] = useState('');
    let [res_cell,res_cell_set] = useState('');
    let [res_email,res_email_set] = useState('');
    let [po_no,po_no_set] = useState('');
    let [proj_detail_date,proj_detail_date_set] = useState(currentDateTime);
    let [proj_detail_start_date,proj_detail_start_date_set] = useState(currentDateTime);
    let [proj_detail_handover_date,proj_detail_handover_date_set] = useState(currentDateTime);
    let [proj_detail_supply,proj_detail_supply_set] = useState(false);
    let [proj_detail_install,proj_detail_install_set] = useState(false);

    let [service_by_name,service_by_name_set] = useState('');
    let [service_by_designation,service_by_designation_set] = useState('');
    let [service_by_id,service_by_id_set] = useState('');
 
    let [selectedEmployee,selectedEmployeeSet] = useState(null);
    
    
    const [issue_date, issue_date_set] = useState(currentDateTime);



    const [scope_install_1, scope_install_1_set] = useState(false);
    const [scope_install_2, scope_install_2_set] = useState(false);
    const [scope_install_3, scope_install_3_set] = useState(false);
    const [scope_install_4, scope_install_4_set] = useState(false);
    const [scope_install_5, scope_install_5_set] = useState(false);
    const [scope_install_6, scope_install_6_set] = useState(false);
    const [scope_install_7, scope_install_7_set] = useState(false);
    const [scope_install_remark_1, scope_install_remark_1_set] = useState('');
    const [scope_install_remark_2, scope_install_remark_2_set] = useState('');
    const [scope_install_remark_3, scope_install_remark_3_set] = useState('');
    const [scope_install_remark_4, scope_install_remark_4_set] = useState('');
    const [scope_install_remark_5, scope_install_remark_5_set] = useState('');
    const [scope_install_remark_6, scope_install_remark_6_set] = useState('');
    const [scope_install_remark_7, scope_install_remark_7_set] = useState('');


    const [proj_manager_name, proj_manager_name_set] = useState('');
    const [proj_manager_designation, proj_manager_designation_set] = useState('');
    const [proj_res_name, proj_res_name_set] = useState('');
    const [proj_res_designation, proj_res_designation_set] = useState('');

 
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }
    
    const getHandOver = async ()=>{
      await axios.post(`${API_URL}/api/get-handover-record`,{id : pathSpliter(location.pathname,3)},{headers:{'auth-token':authInfo.token}}).then(async res=>{
        let data = res.data[0];



        id_set(data.id)

        project_name_set(data.project_name)
        phf_no_set(data.phf_no)
        issue_date_set(data.issue_date)
        project_address_set(data.project_address)
        main_customer_set(data.main_customer)
        res_name_set(data.res_name)
        res_designation_set(data.res_designation)
        res_cell_set(data.res_cell)
        res_email_set(data.res_email)
        po_no_set(data.po_no)
        proj_detail_date_set(data.proj_detail_date)
        proj_detail_supply_set(data.proj_detail_supply)
        proj_detail_install_set(data.proj_detail_install)
        proj_detail_start_date_set(proj_detail_start_date)
        proj_detail_handover_date_set(data.proj_detail_handover_date)
        supplier_name_set(data.supplier_name)
        supplier_mobile_set(data.supplier_mobile)
        supplier_email_set(data.supplier_email)
        installer_name_set(data.installer_name)
        installer_email_set(data.installer_email)
        installer_mobile_set(data.installer_mobile)
        com_engineer_name_set(data.com_engineer_name)
        com_engineer_mobile_set(data.com_engineer_mobile)
        com_engineer_email_set(data.com_engineer_email)
        coordinator_name_set(data.coordinator_name)
        coordinator_mobile_set(data.coordinator_mobile)
        coordinator_email_set(data.coordinator_email)
        head_res_name_set(data.head_res_name)
        head_res_mobile_set(data.head_res_mobile)
        head_res_email_set(data.head_res_email)
        scope_install_1_set(data.scope_install_1 == '0'?false:true)
        scope_install_2_set(data.scope_install_2 == '0'?false:true)
        scope_install_3_set(data.scope_install_3 == '0'?false:true)
        scope_install_4_set(data.scope_install_4 == '0'?false:true)
        scope_install_5_set(data.scope_install_5 == '0'?false:true)
        scope_install_6_set(data.scope_install_6 == '0'?false:true)
        scope_install_7_set(data.scope_install_7 == '0'?false:true)
        proj_manager_name_set(data.proj_manager_name)
        proj_manager_designation_set(data.proj_manager_designation)
        proj_res_name_set(data.proj_res_name)
        proj_res_designation_set(data.proj_res_designation)
        scope_install_remark_1_set(data.scope_install_remark_1)
        scope_install_remark_2_set(data.scope_install_remark_2)
        scope_install_remark_3_set(data.scope_install_remark_3)
        scope_install_remark_4_set(data.scope_install_remark_4)
        scope_install_remark_5_set(data.scope_install_remark_5)
        scope_install_remark_6_set(data.scope_install_remark_6)
        scope_install_remark_7_set(data.scope_install_remark_7)
        action_set('update')


        console.log(scope_install_1,' llllllll')
        
      })
    }

    const handleDateChange = (date) => {
        issue_date_set(date);
      };

    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{
         
            loadingSaveSet(true);


            await axios.post(`${API_URL}/api/save-handover-entry`,{
              project_name:project_name.trim(),
              phf_no:phf_no.trim(),
              issue_date,
              project_address,
              main_customer,
              res_name,
              res_designation,
              res_cell,
              res_email,
              po_no,
              proj_detail_date,
              proj_detail_supply,
              proj_detail_install,
              proj_detail_start_date,
              proj_detail_handover_date,
              supplier_name,
              supplier_mobile,
              supplier_email,
              installer_name,
              installer_email,
              installer_mobile,
              com_engineer_name,
              com_engineer_mobile,
              com_engineer_email,
              coordinator_name,
              coordinator_mobile,
              coordinator_email,
              head_res_name,
              head_res_mobile,
              head_res_email,
              scope_install_1,
              scope_install_2,
              scope_install_3,
              scope_install_4,
              scope_install_5,
              scope_install_6,
              scope_install_7,
              proj_manager_name,
              proj_manager_designation,
              proj_res_name,
              proj_res_designation,
              scope_install_remark_1,
              scope_install_remark_2,
              scope_install_remark_3,
              scope_install_remark_4,
              scope_install_remark_5,
              scope_install_remark_6,
              scope_install_remark_7,
              action,
              id
            },{headers:{'auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
              getAccounts()
              if(res.data.error){
                swal({
                  title: res.data.msg,
                  icon:'warning'
                })
              }else{
                swal({
                  title: res.data.msg,
                  icon:'success'
                })

               window.location.reload()

             



              }
              
            });
          
          
    }

    let [defaults,defaultsSet] = useState(['Discount on purchase','discount on service','Vat & Tax Account','Discount on sale',
                                'Sales','Sales Return','Discount on service','Purchase','Purchase Return','Salary','Services','Service Expense'])


    const getAccCode = async ()=>{
      await axios.post(`${API_URL}/api/get-handover-code`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        phf_no_set(res.data)
      })
    }

  
    const getAccounts = async ()=>{
      await axios.post(`${API_URL}/api/get-accounts`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        accountsSet(res.data)
      })
    }

    const getLocations = async ()=>{
      await axios.post(`${API_URL}/api/get-locations`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        locationsSet(res.data)
      })
    }


    const getEmployees = async ()=>{
      await axios.post(`${API_URL}/api/get-employees`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        employeesSet(res.data)
      })
    }



    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>accountEdit(props.rowData)}/>
      
        {/* <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>accountDelete(props.rowData)}/> */}
            
   </>):''
 }
      
      </div>)
    
    }


    const accountEdit = (row,index)=>{
      let account =  accounts.filter((account)=>account.id == row[0])

      // if(account[0].project_name.trim().toLowerCase() == 'cash'){
      //   swal({
      //     title: `This Account  Can't be Edit.`,
      //     icon:'warning'
      //   });
      //   return false
      // }

      
      let result =   defaults.findIndex((acc)=>{
        let real = acc.trim().toLowerCase()
        let check = account[0].project_name.trim().toLowerCase()
          return real == check
        })

     if(result > -1){
      swal({
        title: `This Account  Can't be Edit.`,
        icon:'warning'
      });
      return false
     }


      id_set(account[0].id)
      project_name_set(account[0].project_name)
      action_set('update')


      selectedEmployeeSet({employee_name:account[0].employee_name,employee_id:account[0].employee_id})
   
      phf_no_set(account[0].phf_no)

      



    }
    const [customer_name, customer_name_set] = useState("");



    useEffect(()=>{
        getCustomers()
        },[customer_name])


    const getCustomers = async() => {
        await axios.post(`${API_URL}/api/get-accounts-by-search`,{query:customer_name.trim(),type:'debitor'},{headers:{'auth-token':authInfo.token}}).then(res=>{
       
         let newArray = res.data;
          customersSet(newArray)
   
         
   
   
       })
   };
   


    const accountDelete = async (row)=>{

      let account =  accounts.filter((account)=>account.id == row[0])


      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{

        

      let result =   defaults.findIndex((acc)=>{
        let real = acc.trim().toLowerCase()
        let check = account[0].project_name.trim().toLowerCase()
          return real == check
        })

     if(result > -1){
      swal({
        title: `This Account  Can't be Deleted.`,
        icon:'warning'
      });
      return false
     }
    
        if(yes){
          await axios.post(`${API_URL}/api/delete-account`,{id:account[0].id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getAccounts();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "id",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "project_name",label: "Account Name",options: {filter: true,sort: true}},
      {name: "acc_type_name",label: "Account Type",options: {filter: true,sort: true}},
      {name: "phf_no",label: "Account Code",options: {filter: true,sort: true}},
      {name: "opening_balance",label: "Opening Balance",options: {filter: true,sort: true}},

      {name: "creation_date",label: "Creation Date",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ moment(tableMeta.rowData[5]).format(dateFormat) }</p>) 
        }
      }
      
    },

      
      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }




    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>HandOver Entry </h2>

<Grid container>


      <Grid item xs={12} sm={4}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          // inputRef={project_name_ref}
          // onKeyDown={event => {
          //   if (event.key === "Enter") {
          //     acc_type_ref.current.focus()
          //   }
          // }}
        label="PHF NO" name="phf_no" value={phf_no} variant="outlined" size="small" onChange={(e)=>phf_no_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={4}></Grid>

        <Grid item xs={12} sm={4} style={{marginTop:'-20px'}}> 
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
       
        <KeyboardDatePicker
          margin="normal"
          label="ISSUE DATE"
          format="MM/dd/yyyy"

     
          value={issue_date}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        
    
    </MuiPickersUtilsProvider>
        </Grid>

        </Grid>

          <h3 style={{textAlign:'left'}}>Project Information : </h3>
        <Grid container>
            <Grid item xs={12} sm={12}> 
            <TextField  autoComplete='off' style={{marginBottom:'8px'}} className={classes.fullWidth} 
        
            label="Project Name" name="project_name" value={project_name} variant="outlined" size="small" onChange={(e)=>project_name_set(e.target.value)} />
            </Grid>

            <Grid item xs={12} sm={12} style={{marginBottom:'5px'}}> 
        <TextareaAutosize  value={project_address}  name="project_address" onChange={(e)=>project_address_set(e.target.value)} style={{float:'left',marginTop:'',width: '100%'}} aria-label="Address" 
         rowsMin={3} placeholder="Project Address" />
        </Grid>

      

        
       

        </Grid>

        <Grid item xs={12} sm={12}> 
            <TextField  autoComplete='off' style={{marginBottom:'8px'}} className={classes.fullWidth} 
        
            label="Main customer Name" name="main_customer" value={main_customer} variant="outlined" size="small" onChange={(e)=>main_customer_set(e.target.value)} />
            </Grid>

      
        


            <Grid container spacing={2} >
           <Grid item xs={12} sm={6}>
           <h2 style={{textAlign:'left'}}>PROJECT RESPONSIBLE: </h2>

               <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Name" name="res_name" value={res_name} variant="outlined" 
                size="small" onChange={(e)=>res_name_set(e.target.value)} />
                </Grid>

                <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Designation" name="res_designation" value={res_designation} variant="outlined" 
                size="small" onChange={(e)=>res_designation_set(e.target.value)} />
                </Grid>



           </Grid>

           <Grid item xs={12} sm={6} style={{marginTop:'48px'}}>
           <h2 style={{textAlign:'left',}}></h2>

               <Grid item xs={12} sm={12} style={{marginBottom:'20px',}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Cell" name="res_cell" value={res_cell} variant="outlined" 
                size="small" onChange={(e)=>res_cell_set(e.target.value)} />
                </Grid>

                <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Email" name="res_email" value={res_email} variant="outlined" 
                size="small" onChange={(e)=>res_email_set(e.target.value)} />
                </Grid>

           


           </Grid>
        </Grid>


        <h3 style={{textAlign:'left'}}>PROJECT DETAILS:  </h3>
        
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} spacing={2}>
            <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
        <TextField  autoComplete='off'  className={classes.fullWidth} 
       
        label="PO/ WORK ORDER NO :" name="po_no" value={po_no} variant="outlined" 
        size="small" onChange={(e)=>po_no_set(e.target.value)} />
        </Grid>


        

        <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
      
              
            <label>
        <input type="checkbox"
          defaultChecked={proj_detail_supply}
          onChange={() => proj_detail_supply_set(!proj_detail_supply)}
        />
        
        <span className='checktitle'>SUPPLY &</span>

      </label>

      <label>
        <input type="checkbox"
          defaultChecked={proj_detail_install}
          onChange={() => proj_detail_install_set(!proj_detail_install)}
        />
        
        <span className='checktitle'>INSTALLATION OF</span>

      </label>


        </Grid>


        <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
             
        <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label=" Date" 
          inputFormat={dateTimeFormat}
          value={proj_detail_date}
          onChange={(e)=>proj_detail_date_set(e)}
          renderInput={(params) => <TextField {...params} />}
        />
        
      </Stack>
    </LocalizationProvider>

        </Grid>

            </Grid>

            <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="PROJECT START DATE" 
          inputFormat={dateTimeFormat}
          value={proj_detail_start_date}
          onChange={(e)=>proj_detail_start_date_set(e)}
          renderInput={(params) => <TextField {...params} />}
        />
        
      </Stack>
    </LocalizationProvider>





    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="PROJECT HANDOVER DATE" 
          inputFormat={dateTimeFormat}
          value={proj_detail_handover_date}
          onChange={(e)=>proj_detail_handover_date_set(e)}
          renderInput={(params) => <TextField {...params} />}
        />
        
      </Stack>
    </LocalizationProvider>




             </Grid>


             
             
            
       
        </Grid>





        <Grid container spacing={2} >
        <h2 style={{textAlign:'left'}}>SUPPLIER / INSTALLER INFORMATION : </h2>

           <Grid container  style={{border:'1px solid #222'}} spacing={2} >

               <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="NAME OF SUPPLIER" name="supplier_name" value={supplier_name} variant="outlined" 
                size="small" onChange={(e)=>supplier_name_set(e.target.value)} />
                </Grid>

                <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="CONTACT NO." name="supplier_mobile" value={supplier_mobile} variant="outlined" 
                size="small" onChange={(e)=>supplier_mobile_set(e.target.value)} />
                </Grid>
                
                <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="E-mail" name="supplier_email" value={supplier_email} variant="outlined" 
                size="small" onChange={(e)=>supplier_email_set(e.target.value)} />
                </Grid>
           </Grid>


           <Grid container style={{border:'1px solid #222',marginTop:'20px'}}  spacing={2}>

               <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="NAME OF INSTALLAR" name="installer_name" value={installer_name} variant="outlined" 
                size="small" onChange={(e)=>installer_name_set(e.target.value)} />
                </Grid>

                <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="CONTACT NO." name="installer_mobile" value={installer_mobile} variant="outlined" 
                size="small" onChange={(e)=>installer_mobile_set(e.target.value)} />
                </Grid>
                
                <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="E-mail" name="installer_email" value={installer_email} variant="outlined" 
                size="small" onChange={(e)=>installer_email_set(e.target.value)} />
                </Grid>
           </Grid>

           <Grid container style={{border:'1px solid #222',marginTop:'20px'}}  spacing={2}>

          <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
          <TextField  autoComplete='off' className={classes.fullWidth} 

          label="PROJECT COMMISSIONING ENGINEER" name="com_engineer_name" value={com_engineer_name} variant="outlined" 
          size="small" onChange={(e)=>com_engineer_name_set(e.target.value)} />
          </Grid>

          <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
          <TextField  autoComplete='off' className={classes.fullWidth} 

          label="CONTACT NO." name="com_engineer_mobile" value={com_engineer_mobile} variant="outlined" 
          size="small" onChange={(e)=>com_engineer_mobile_set(e.target.value)} />
          </Grid>
          
          <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
          <TextField  autoComplete='off' className={classes.fullWidth} 

          label="E-mail" name="com_engineer_email" value={com_engineer_email} variant="outlined" 
          size="small" onChange={(e)=>com_engineer_email_set(e.target.value)} />
          </Grid>
          </Grid>




          <Grid container style={{border:'1px solid #222',marginTop:'20px'}}  spacing={2}>

          <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
          <TextField  autoComplete='off' className={classes.fullWidth} 

          label="PROJECT CO-COORDINATOR" name="coordinator_name" value={coordinator_name} variant="outlined" 
          size="small" onChange={(e)=>coordinator_name_set(e.target.value)} />
          </Grid>

          <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
          <TextField  autoComplete='off' className={classes.fullWidth} 

          label="CONTACT NO." name="coordinator_mobile" value={coordinator_mobile} variant="outlined" 
          size="small" onChange={(e)=>coordinator_mobile_set(e.target.value)} />
          </Grid>

          <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
          <TextField  autoComplete='off' className={classes.fullWidth} 

          label="E-mail" name="coordinator_email" value={coordinator_email} variant="outlined" 
          size="small" onChange={(e)=>coordinator_email_set(e.target.value)} />
          </Grid>
          </Grid>


            <Grid container style={{border:'1px solid #222',marginTop:'20px'}}  spacing={2}>

          <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
          <TextField  autoComplete='off' className={classes.fullWidth} 

          label="HEAD OF RESPONSIBLE DEPARTMEN" name="head_res_name" value={head_res_name} variant="outlined" 
          size="small" onChange={(e)=>head_res_name_set(e.target.value)} />
          </Grid>

          <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
          <TextField  autoComplete='off' className={classes.fullWidth} 

          label="CONTACT NO." name="head_res_mobile" value={head_res_mobile} variant="outlined" 
          size="small" onChange={(e)=>head_res_mobile_set(e.target.value)} />
          </Grid>

          <Grid item xs={12} sm={6} style={{marginBottom:'20px'}}> 
          <TextField  autoComplete='off' className={classes.fullWidth} 

          label="E-mail" name="head_res_email" value={head_res_email} variant="outlined" 
          size="small" onChange={(e)=>head_res_email_set(e.target.value)} />
          </Grid>
          </Grid>



          <h2>PROJECT INITIATION INFORMATION (HANDOVER TO PROJECT MANAGER)</h2>

          <Grid container style={{border:'1px solid #222',marginTop:'20px'}}  spacing={2}>
            <table style={{width: '100%',
  borderCollapse: 'collapse'}}>
            <tr>
                <th>SL</th>
                <th>SCOPE OF INSTALLATION JOB</th>
                <th>STATUS</th>
                <th>REMARKS</th>
              </tr>
              <tr>
                <td>1</td>
                <td>The contract has been signed</td>
                <td> <label>
                  <input type="checkbox"
                    defaultChecked={scope_install_1}
                    onChange={() => scope_install_1_set(!scope_install_1)}
                  />
                  
                  <span className='checktitle'>YES</span>

                </label>
               </td>
                <td>
                <TextField  autoComplete='off' className={classes.fullWidth} 
                label="" name="scope_install_remark_1" value={scope_install_remark_1} variant="outlined" 
                size="small" onChange={(e)=>scope_install_remark_1_set(e.target.value)} />
                </td>
              
              </tr>



              <tr>
                <td>2</td>
                <td>The Standpipe / Sprinkler system is 100% successfully installed.</td>
                <td> <label>
                  <input type="checkbox"
                    defaultChecked={scope_install_2}
                    onChange={() => scope_install_2_set(!scope_install_2)}
                  />
                  
                  <span className='checktitle'>YES</span>

                </label>
               </td>
                <td>
                <TextField  autoComplete='off' className={classes.fullWidth} 
                label="" name="scope_install_remark_2" value={scope_install_remark_2} variant="outlined" 
                size="small" onChange={(e)=>scope_install_remark_2_set(e.target.value)} />
                </td>
              
              </tr>


              <tr>
                <td>3</td>
                <td>The System is 100% successfully programmed & commissioned</td>
                <td> <label>
                  <input type="checkbox"
                    defaultChecked={scope_install_3}
                    onChange={() => scope_install_3_set(!scope_install_3)}
                  />
                  
                  <span className='checktitle'>YES</span>

                </label>
               </td>
                <td>
                <TextField  autoComplete='off' className={classes.fullWidth} 
                label="" name="scope_install_remark_3" value={scope_install_remark_3} variant="outlined" 
                size="small" onChange={(e)=>scope_install_remark_3_set(e.target.value)} />
                </td>
              
              </tr>


              <tr>
                <td>4</td>
                <td>The System is 100% successfully tested after commissioned</td>
                <td> <label>
                  <input type="checkbox"
                    defaultChecked={scope_install_4}
                    onChange={() => scope_install_4_set(!scope_install_4)}
                  />
                  
                  <span className='checktitle'>YES</span>

                </label>
               </td>
                <td>
                <TextField  autoComplete='off' className={classes.fullWidth} 
                label="" name="scope_install_remark_4" value={scope_install_remark_4} variant="outlined" 
                size="small" onChange={(e)=>scope_install_remark_4_set(e.target.value)} />
                </td>
              
              </tr>


              <tr>
                <td>5</td>
                <td>The System is 100% successfully running now</td>
                <td> <label>
                  <input type="checkbox"
                    defaultChecked={scope_install_5}
                    onChange={() => scope_install_5_set(!scope_install_5)}
                  />
                  
                  <span className='checktitle'>YES</span>

                </label>
               </td>
                <td>
                <TextField  autoComplete='off' className={classes.fullWidth} 
                label="" name="scope_install_remark_5" value={scope_install_remark_5} variant="outlined" 
                size="small" onChange={(e)=>scope_install_remark_5_set(e.target.value)} />
                </td>
              
              </tr>


              <tr>
                <td>6</td>
                <td>Provided complete training all personnel assigned by the customer .</td>
                <td> <label>
                  <input type="checkbox"
                    defaultChecked={scope_install_6}
                    onChange={() => scope_install_6_set(!scope_install_6)}
                  />
                  
                  <span className='checktitle'>YES</span>

                </label>
               </td>
                <td>
                <TextField  autoComplete='off' className={classes.fullWidth} 
                label="" name="scope_install_remark_6" value={scope_install_remark_6} variant="outlined" 
                size="small" onChange={(e)=>scope_install_remark_6_set(e.target.value)} />
                </td>
              
              </tr>



              <tr>
                <td>7</td>
                <td>Customer responsible person are satisfy with products quality & service</td>
                <td> <label>
                  <input type="checkbox"
                    defaultChecked={scope_install_7}
                    onChange={() => scope_install_7_set(!scope_install_7)}
                  />
                  
                  <span className='checktitle'>YES</span>

                </label>
               </td>
                <td>
                <TextField  autoComplete='off' className={classes.fullWidth} 
                label="" name="scope_install_remark_7" value={scope_install_remark_7} variant="outlined" 
                size="small" onChange={(e)=>scope_install_remark_7_set(e.target.value)} />
                </td>
              
              </tr>

            </table>
            </Grid>

        </Grid>


        <Grid container spacing={2} style={{border:'1px solid #222',marginTop:'30px'}}>
           <Grid item xs={12} sm={6}>
           <h2 style={{textAlign:'left'}}>PROJECT RECEIVED BY: </h2>

               <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Name" name="proj_manager_name" value={proj_manager_name} variant="outlined" 
                size="small" onChange={(e)=>proj_manager_name_set(e.target.value)} />
                </Grid>

                <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Designation" name="proj_manager_designation" value={proj_manager_designation} variant="outlined" 
                size="small" onChange={(e)=>proj_manager_designation_set(e.target.value)} />
                </Grid>

              

           </Grid>

           <Grid item xs={12} sm={6}>
           <h2 style={{textAlign:'left'}}>PROJECT RESPONSIBLE (Engineer): </h2>

               <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Name" name="proj_res_name" value={proj_res_name} variant="outlined" 
                size="small" onChange={(e)=>proj_res_name_set(e.target.value)} />
                </Grid>

                <Grid item xs={12} sm={12} style={{marginBottom:'20px'}}> 
                <TextField  autoComplete='off' className={classes.fullWidth} 
            
                label="Designation" name="proj_res_designation" value={proj_res_designation} variant="outlined" 
                size="small" onChange={(e)=>proj_res_designation_set(e.target.value)} />
                </Grid>

           


           </Grid>
        </Grid>




       


       


  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

     

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
      position:'relative'  
},
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    }

}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);